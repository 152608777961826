import { ConnectKitButton } from "connectkit";
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { ConnectButton } from './ConnectButton'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import Typed from "typed.js";
import { useEffect, useRef } from "react";


export default function CTA() {
    const el = useRef(null);

    useEffect(() => {
      const typed = new Typed(el.current, {
        strings: ["USDT", "OP", "FTM", "BNB", "AVAX", "any token"], 
        startDelay: 0,
        typeSpeed: 100,
        backSpeed: 100,
        backDelay: 200,
        smartBackspace: true,
        showCursor: true,
        cursorChar: "!"
      });
  
      // Destropying
      return () => {
        typed.destroy();
      };
    }, []);
 
  const faqs = [
    {
      question: "What is Surge protocol?",
      answer:
        "Surge Protocol is a decentralized lending and borrowing platform that uses a dynamic collateral ratio as a function of utilization rate over time rather than relying on price feeds. This allows for a more robust and reliable lending and borrowing experience for users of all tokens.",
    },
    {
      question: "How does Surge Protocol differ from other DeFi lending protocols?",
      answer:
        "Other DeFi lending protocols typically only support a small number of highly liquid tokens due to the lack of reliable and manipulation-resistant price feeds. Surge Protocol eliminates this limitation by using a dynamic collateral ratio based on utilization rate rather than price feeds.",
    },
    {
      question: "Who can deploy a lending pool on Surge Protocol?",
      answer:
        "Anyone can deploy a lending pool on Surge Protocol by following the simple process outlined in the docs. Pool deployers determine all pool parameters, but these parameters cannot be changed once the pool is deployed.",
    },
    {
      question: "What are collateral ratio and utilization ratio?",
      answer:
        "Collateral ratio is a risk parameter decided by lenders to set the maximum loan limits. It represent the pool’s risk score where high collateral ratio protects the lender from bad debt, low collateral ratio creates a sustainable credit platform. It is basically a risk assessment structure.",
    },    
    {
      question: "Will Surge be deployed on [my favorite chain]?",
      answer:
        "We plan to first deploy Surge Protocol on Ethereum mainnet, followed by L2s, and other EVM chains (in that order).",
    },
  ]

  


  return (
      <div className="mx-auto sm:px-0 px-6 max-w-7xl">
        <div className="mx-auto max-w-6xl py-2 px-3 sm:px-6 lg:py-14 lg:px-24 text-center sm:text-center">
          <a
            href="https://medium.com/surge-fi/introduction-to-surge-protocol-overview-34cc828d7c50"
            target="_blank"
            className="inline-flex font-medium text-gray-300 rounded-full border-2 border-black-400 hover:bg-black-400 hover:bg-black-400 px-2 py-1 mb-4">
            <img src="announcement-icon.png" className="h-6 w-6 flex-shrink-0 mr-1" alt="announcement" />
            <span>Read our launch announcement on Medium</span>
            <ArrowRightIcon className="h-6 w-6 flex-shrink-0 text-primary-500 ml-1" aria-hidden="true" />
          </a>
            <h2 className="mx-auto text-4xl font-bold leading-2 sm:leading-1 tracking-tight text-white sm:text-7xl text-center">
            Borrow, lend, long or short <br className="hidden sm:block" />
            <div>
              <span ref={el} className="text-primary-500"></span>
            </div>
            </h2>
            <p className="mx-auto sm:mt-3 mt-3 sm:mt-4 max-w-2xl sm:max-w-xl text-xl sm:text-xl font-normal text-gray-300 text-center">
            Permission-less, oracle-less and admin-less lending protocol.
            </p>
            <div className="mt-12 flex items-center justify-center">
            <ConnectButton/>
            </div>
        </div>
        
        <div className="sm:mx-0 mt-8 sm:mt-16">
          <div className="mx-auto shadow-xl pool-card w-full lg:h-[650px] max-w-[1170px]">
            <img src="pool.svg" className="rounded-xl lg:h-[650px]" alt="hero" />  
          </div>
        </div>

        <div className="mx-auto py-16 sm:py-32">
          <div className="text-xl sm:text-4xl font-bold text-gray-300 text-center">
          Unlocking access to DeFi lending for <span className="text-primary-500">everyone</span>
          </div>
          <div className="text-lg sm:text-xl font-normal text-gray-300 text-center mt-4">
          DeFi lending should be available for all tokens, not just a few.
          </div>
          <div className="mt-3 text-center text-black-900 italic text-lg">
            <p>"99% of tokens aren't supported in most DeFi lending protocols."</p>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">

          <div className="feat-card">
            <img src="feat/permessionless.svg" className="feat-icon" alt="" />
            <div className="mt-6 text-white font-semibold text-2xl">Deploy without permission</div>
            <div className="mt-3 text-gray-400 text-base">Anyone can create a pool for any token. Deploying a new Surge lending pool is as simple as deploying a Uniswap pair.</div>
          </div>

          <div className="feat-card">
            <img src="feat/oracless.svg" className="feat-icon" alt="" />
            <div className="mt-6 text-white font-semibold text-2xl">Never wait for an oracle again</div>
            <div className="mt-3 text-gray-400 text-base">Surge pools don't depend on any oracles when it comes to borrowing, lending or liquidations.</div>
          </div>

          <div className="feat-card">
            <img src="feat/autonomous.svg" className="feat-icon" alt="" />
            <div className="mt-6 text-white font-semibold text-2xl">Trust nobody</div>
            <div className="mt-3 text-gray-400 text-base">The protocol cannot be paused or upgraded. It functions without any sort of governance, a DAO or admin keys.</div>
          </div>

          <div className="feat-card sm:col-span-2">
            <img src="feat/bulb.svg" className="feat-icon" alt="" />
            <div className="mt-6 text-white font-semibold text-2xl">The sky is the limit</div>
            <div className="mt-3 text-gray-400 text-base">Surge lets anyone deploy and configure a new pool. Pool deployers can experiment with creative interest rate models, dynamic collateral ratio models and more.</div>
          </div>

          <div className="feat-card">
            <img src="feat/multi.svg" className="feat-icon" alt="" />
            <div className="mt-6 text-white font-semibold text-2xl">Multi chain</div>
            <div className="mt-3 text-gray-400 text-base">Surge is coming to all EVM chains and L2s, starting with Arbitrum.</div>
          </div>

        </div>

        <div className="sm:py-32 py-16">
          <div className="mx-auto max-w-4xl divide-y divide-white/10">
            <h2 className="text-2xl sm:text-4xl font-bold text-gray-300 text-center">Frequently asked questions</h2>
            <dl className="mt-10 space-y-6 divide-y divide-white/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                          <span className="text-lg font-semibold leading-7">{faq.question}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-4 pr-12">
                        <p className="text-base leading-7 text-gray-400">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
        
      </div>

      
    )
  }
  