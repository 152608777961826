import usePoolPositions from "../hooks/usePoolPositions"
import { useNetwork } from "wagmi"
import { Square3Stack3DIcon } from "@heroicons/react/24/outline"
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useState } from "react"

  function shortenAddress(address) {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
  }

  export default function PoolPositions({address, collateralRatioRaw, collateralDecimals, loanDecimals, loanSymbol, collateralSymbol, onLiquidate}) {
    const { chain } = useNetwork()
    const explorerPrefix = chain?.blockExplorers.default.url;
    // sort liquidatable first and then by debt
    const positions = usePoolPositions({address, collateralRatioRaw, collateralDecimals, loanDecimals})
      .sort((a, b) => {
        if (a.liquidatable && !b.liquidatable) return -1;
        if (!a.liquidatable && b.liquidatable) return 1;
        return b.debt - a.debt;
      })
    const itemsPerPage = 10;
    const [page, setPage] = useState(1);

    const numPages = Math.ceil(positions.length / itemsPerPage);

    const showingFrom = (page - 1) * itemsPerPage + 1;
    const showingTo = Math.min(page * itemsPerPage, positions.length);

    const positionsInPage = positions.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    function nextPage (e) {
      e.preventDefault()
      if (page === numPages) return;
      setPage(page + 1);
    }

    function prevPage (e) {
      e.preventDefault()
      if (page === 1) return;
      setPage(page - 1);
    }

    return (
      <div className="mt-8">
         <div>
            <h2 className="font-medium text-lg leading-6 text-white">Active borrowers</h2>
        </div>
        <div className="-mx-4 mt-4 overflow-hidden shadow mx-0 rounded-lg bg-black-200">
          <table className="min-w-full divide-y divide-black-400">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">
                  Borrower
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white lg:table-cell"
                >
                  Debt
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell"
                >
                  Collateral
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                  <span className="hidden sm:inline">Collateral ratio</span><span className="sm:hidden">CR</span>
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Liquidate</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-black-400 bg-black-200">
              {positionsInPage.map((position) => (
                <tr key={position.borrower}>
                  <td className="py-4 pl-4 pr-3 text-sm text-white sm:w-auto sm:max-w-none sm:pl-6">
                    <a className=" underline" href={explorerPrefix + "/address/" + position.borrower} target="_blank">{shortenAddress(position.borrower)}</a>
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Borrower</dt>
                      <dd className="mt-1 truncate text-gray-700">{position.debt}</dd>
                      <dt className="sr-only sm:hidden">Debt</dt>
                      <dd className="mt-1 truncate text-gray-400 sm:hidden">{position.collateralBalance}</dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-400 lg:table-cell">{position.debt} {loanSymbol}</td>
                  <td className="hidden px-3 py-4 text-sm text-gray-400 sm:table-cell">{position.collateralBalance} {collateralSymbol}</td>
                  <td className="px-3 py-4 text-sm text-gray-400">{position.collateralRatio} : 1</td>
                  {position.liquidatable && 
                    <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <a onClick={() => onLiquidate({borrower: position.borrower, debtRaw: position.debtRaw, debt: position.debt})} className="text-primary-500 hover:text-primary-600 cursor-pointer">
                        Liquidate
                    </a>
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
      <div className="flex items-center justify-between border-t border-black-500 bg-black-200 px-4 py-3 sm:px-6">
      {positions.length > 0 && <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          onClick={prevPage}
          className="relative inline-flex items-center rounded-md bg-black-300 px-4 py-2 text-sm font-medium text-white hover:bg-black-400"
        >
          Previous
        </a>
        <a
          href="#"
          onClick={nextPage}
          className="relative ml-3 inline-flex items-center rounded-md bg-black-300 px-4 py-2 text-sm font-medium text-white hover:bg-black-400"
        >
          Next
        </a>
      </div>}

      {positions.length > 0 && <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-white">
            Showing <span className="font-medium">{showingFrom}</span> to <span className="font-medium">{showingTo}</span> of{' '}
            <span className="font-medium">{positions.length}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <a
              href="#"
              onClick={prevPage}
              className="relative inline-flex items-center rounded-l-md bg-black-300 px-2 py-2 text-sm font-medium text-white hover:bg-black-400 focus:z-20"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            <a
              href="#"
              onClick={nextPage}
              className="relative inline-flex items-center rounded-r-md bg-black-300 px-2 py-2 text-sm font-medium text-white hover:bg-black-400 focus:z-20"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>}
    </div>
          {positions.length === 0 &&
                <div className="text-center m-12">
                {/* <Square3Stack3DIcon className="mx-auto h-8 w-8 text-gray-400" /> */}
                <span className="mx-auto emoji text-4xl">💰🤔</span>
                <h3 className="mt-2 text-medium font-semibold text-white">No active borrowers yet</h3>
                <p className="mt-1 text-sm text-gray-400">Future active borrow positions will be displayed here.</p>
              </div>
              }
        </div>
      </div>
    )
  }
  