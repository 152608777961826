import CONSTANTS from "../constants";
import { useNetwork } from "wagmi";
import FavoriteButton from "./FavoriteButton";
import TokenIcon from './TokenIcon';

export default function PoolItem({ pool, hideEmpty }) {

    const { chain } = useNetwork()
    const network = chain?.network;
    const prefix = CONSTANTS.networks[network]?.prefix;

    function goToPoolPage() {
        window.open(`/pool/${prefix}${pool.poolNumber}`, "_blank")
    }

    const hide = hideEmpty && Number(pool.supplied) === 0;

    return (
          <>
            {!hide && <tr onClick={() => goToPoolPage()} className="cursor-pointer bg-black-200 hover:bg-black-300">
                <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm">
                  <div className="font-medium text-white inline-flex">
                    <FavoriteButton address={pool.address} /> {prefix}{pool.poolNumber}
                  </div>
                  <div className="sm:hidden text-left text-white"><TokenIcon url={pool.loanImage}/>{pool.loanSymbol}</div> 
                  <div className="sm:hidden text-left text-white"><TokenIcon url={pool.collateralImage}/>{pool.collateralSymbol}</div>
                </td>
                <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm hidden sm:table-cell">
                  <div className="font-medium text-white">
                  <TokenIcon url={pool.loanImage}/>{pool.loanSymbol}
                  </div>
                </td>

                <td className="relative py-4 pl-4 pr-3 text-sm hidden sm:table-cell">
                  <div className="font-medium text-white">
                  <TokenIcon url={pool.collateralImage}/>{pool.collateralSymbol}
                  </div>
                </td>

                <td className="relative py-4 pl-4 pr-3 text-sm">
                  <div className="font-medium text-white">
                    {pool.liquidity} <span className="hidden">{pool.loanSymbol}</span> (${pool.liquidityUSD.toFixed(0)})
                  </div>
                  <div className="mt-1 flex flex-col text-gray-400 sm:block hidden">
                    <span>
                      {pool.supplied} <span className="hidden">{pool.loanSymbol}</span>
                    </span>
                  </div>
                </td>

                <td className="relative py-4 pl-4 pr-3 text-sm">
                  <div className="font-medium text-white">
                    {pool.collateralRatio} <span className="hidden">{pool.loanSymbol} </span>: 1 <span className="hidden">{pool.collateralSymbol}</span>
                  </div>
                  <div className="mt-1 flex flex-col text-gray-400 sm:block hidden">
                    <span>
                      {pool.maxCollateralRatio} <span className="hidden">{pool.loanSymbol} </span>: 1 <span className="hidden">{pool.collateralSymbol}</span>
                    </span>
                  </div>
                </td>

                <td className="relative py-4 pl-4 pr-3 text-sm hidden sm:table-cell">
                  <div className="font-medium text-white">
                    {pool.utilization}%
                  </div>
                </td>

                <td className="relative py-4 pl-4 pr-3 text-sm hidden sm:table-cell">
                  <div className="font-medium text-white">
                    {pool.supplyRate}%
                  </div>
                </td>

                <td className="relative py-4 pl-4 pr-3 text-sm hidden sm:table-cell">
                  <div className="font-medium text-white">
                    {pool.borrowRate}%
                  </div>
                </td>

                <td className="relative py-4 pl-4 pr-3 text-sm sm:hidden text-white">
                  <div className="font-medium text-white">
                    <div>{pool.supplyRate}%</div>
                    <div>{pool.borrowRate}%</div>
                  </div>
                </td>
            </tr>}
          </>
        )
}