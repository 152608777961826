import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function ToolTip({ text }) {
  return (
    <>
        <span className="relative flex flex-col items-center group ml-0.5">
            <InformationCircleIcon className="h-4 w-4 text-gray-500 hover:text-gray-600" aria-hidden="true" />
            <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
                <span className="relative z-10 p-2 text-xs text-white whitespace-no-wrap bg-black-600 w-max max-w-md shadow-lg rounded">{text}</span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
            </div>
        </span>
    </>
  )
}