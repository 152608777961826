import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

export default function Alert({ title, content, onDismiss }) {
  return (
    <div className="rounded-lg bg-yellow-700/30 p-4 mt-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 mt-1 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-lg font-semibold text-yellow-400">{title}</h3>
          <div className="mt-1 leading-6 text-sm text-white">
            <p>{content}</p>
          </div>
          <div className="mt-4">
            <button
              onClick={onDismiss}
              type="button"
              className="rounded-md bg-yellow-600 px-2 py-1.5 text-sm font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
