import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline'
import { StarIcon as StarIconSolid } from '@heroicons/react/20/solid'
import useFavorites from '../hooks/useFavorites'
import { useState } from 'react'
import {ethers} from 'ethers'

export default function FavoriteButton({ address }) {
    
    const [isHovering, setIsHovering] = useState(false)
    const { favorites, addFavorite, removeFavorite } = useFavorites()

    const isFavorite = favorites.includes(address) || favorites.includes(address.toLowerCase()) || favorites.includes(ethers.utils.getAddress(address))

    function toggleFavorite(e) {
        e.stopPropagation();
        if (isFavorite) {
            removeFavorite(address)
        } else {
            addFavorite(address)
        }
    }
    
    return (
        <span className='px-2 cursor-pointer' onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            {isFavorite || isHovering ? (
                <StarIconSolid
                    className="h-5 w-5 text-yellow-400"
                    onClick={toggleFavorite}
                />
            ) : (
                <StarIconOutline
                    className="h-5 w-5 text-yellow-400"
                    onClick={toggleFavorite}
                />
            )}
        </span>
    )
}