import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useAccount, useContractRead, useSigner } from 'wagmi'
import { ethers } from 'ethers'
import ActionButton from './ActionButton'
import ActionInput from './ActionInput'
import useFavorites from '../hooks/useFavorites'
import numeral from 'numeral'

export default function AddCollateral({open, setOpen, address, collateralDecimals, collateralAddress, onReceipt, collateralSymbol, userCollateralBalanceRaw, userCollateralBalance}) {

  const { addFavorite } = useFavorites()

  const [amount, setAmount] = useState('')

  let amountErrorMessage = '';
  if(amount) {
    if(amount.lte(0)) amountErrorMessage = 'Amount must be greater than 0'
    else if(amount.gt(userCollateralBalanceRaw)) amountErrorMessage = 'Insufficient balance'
  }

  const [buttonText, setButtonText] = useState('Add')
  const [txState, setTxState] = useState("ready")
  let actionState = "disabled";
  if(txState === "ready" && amount && !amountErrorMessage) {
    actionState = "ready"
  } else if(txState === "loading") {
    actionState = "loading"
  } else if(txState === "success") {
    actionState = "success"
  }
  const { address :account } = useAccount()
    function setAmountBigNumber(value) {
      try {
        setAmount(ethers.utils.parseUnits(value, collateralDecimals))
      } catch(e) {
        setAmount('')
      }
    }

    const poolAbi = [
        "function addCollateral(address to, uint) external"
    ];

    const collateralAbi = [
        "function allowance(address, address) external view returns (uint)",
        "function approve(address, uint) external returns (bool)"
    ];

    const { data: allowanceData, refetch: allowanceRefetch } = useContractRead({
      address: collateralAddress,
      abi: collateralAbi,
      functionName: 'allowance',
      args: [account, address]
  })

    const { data: signer } = useSigner()

    async function submit(e) {
      e.preventDefault()
      setTxState("loading")
      try {
        const poolContract = new ethers.Contract(address, poolAbi, signer)
        const collateralContract = new ethers.Contract(collateralAddress, collateralAbi, signer)
          if(allowanceData.lt(amount)) {
              setButtonText('Approving...')
              const approveTx = await collateralContract.approve(address, ethers.constants.MaxUint256);
              await approveTx.wait()
              await allowanceRefetch()
          }
          setButtonText('Adding...')
          const tx = await poolContract.addCollateral(account, amount);
          await tx.wait()
          onReceipt()
          addFavorite(address)
          setTxState("success")
          setButtonText('Added')
          setAmount('')
          setTimeout(() => {
            setTimeout(() => {
              setOpen(false)
              setTxState("ready")
              setButtonText('Add')
            }, 1000)
          }, 2000)
        } catch(e) {
          setTxState("ready")
          setButtonText('Add')
      }
    }

    function close() {
      setAmount('')
      setOpen(false)
    }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
      <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>


        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-black-500 bg-black-200 shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-black-300 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">Add collateral</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={close}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-400">
                            Add collateral to your position
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-black-500 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                          <ActionInput
                            max={ethers.utils.formatUnits(userCollateralBalanceRaw, collateralDecimals)}
                            label="Amount"
                            placeholder={"available: " + numeral(ethers.utils.formatUnits(userCollateralBalanceRaw, collateralDecimals)).format('0,0.000')}
                            unit={collateralSymbol}
                            onChange={setAmountBigNumber}
                            errorMessage={amountErrorMessage}
                            helperText="The amount of collateral you want to add."
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-black-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        onClick={close}
                      >
                        Close
                      </button>
                      <ActionButton
                        onClick={submit}
                        text={buttonText}
                        state={actionState}
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
