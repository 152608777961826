import { useNavigate } from "react-router-dom";
import { useState } from 'react'
import { useNetwork, useSigner } from 'wagmi'
import CONSTANTS from '../constants'
import {ethers} from 'ethers';
import ActionButton from '../components/ActionButton'
import ActionInput from '../components/ActionInput'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import useFavorites from '../hooks/useFavorites'
import Notifications from "../components/Notifications";

export default function Create() {

    const RATE_CEILING = 10000

    const [notification, setNotification] = useState(null)

    function notify(title, message, type) {
        setNotification({
            title,
            message,
            type
        })
        setTimeout(() => {
            setNotification(null)
        }, 5000)
    }

    const { addFavorite } = useFavorites()

    const [collateralToken, setCollateralToken] = useState('')
    const [loanToken, setLoanToken] = useState('')
    const [maxCollateralRatioMantissa, setMaxCollateralRatioMantissa] = useState('')
    const [kinkMantissa, setKinkMantissa] = useState('')
    const [collateralRatioFallDuration, setCollateralRatioFallDuration] = useState('')
    const [collateralRatioRecoveryDuration, setCollateralRatioRecoveryDuration] = useState('')
    const [minRateMantissa, setMinRateMantissa] = useState('')
    const [surgeRateMantissa, setSurgeRateMantissa] = useState('')
    const [maxRateMantissa, setMaxRateMantissa] = useState('')
    const [fallDurationUnit, setFallDurationUnit] = useState('Days')
    const [recoveryDurationUnit, setRecoveryDurationUnit] = useState('Days')

    function isValidEthereumAddress(address) {
        return /^(0x)?[0-9a-f]{40}$/i.test(address);
    }

    const isAnyFieldEmpty = !collateralToken || !loanToken || !maxCollateralRatioMantissa || !kinkMantissa || !collateralRatioFallDuration || !collateralRatioRecoveryDuration || !minRateMantissa || !surgeRateMantissa || !maxRateMantissa

    function areSameAddresses(address1, address2) {
        return address1.toLowerCase() === address2.toLowerCase()
    }

    function isPositiveNumber(value) {
        return !isNaN(value) && value > 0
    }

    const loanTokenErrorMsg = !isValidEthereumAddress(loanToken) ? 'Please enter a valid token address' : areSameAddresses(loanToken, collateralToken) ? 'Loan and collateral tokens cannot be the same' : ''
    const collateralTokenErrorMsg = !isValidEthereumAddress(collateralToken) ? 'Please enter a valid token address' : areSameAddresses(loanToken, collateralToken) ? 'Loan and collateral tokens cannot be the same' : ''
    const maxCollateralRatioMantissaErrorMsg = !isPositiveNumber(maxCollateralRatioMantissa) ? 'Must be greater than 0' : ''
    const kinkMantissaErrorMsg = !isPositiveNumber(kinkMantissa) || Number(kinkMantissa) > 100 ? 'Must be a positive number up to 100' : ''
    const collateralRatioFallDurationErrorMsg = !isPositiveNumber(collateralRatioFallDuration) ? 'Must be greater than 0' : ''
    const collateralRatioRecoveryDurationErrorMsg = !isPositiveNumber(collateralRatioRecoveryDuration) ? 'Must be greater than 0' : ''
    const minRateMantissaErrorMsg = Number(minRateMantissa) < 0 || Number(minRateMantissa) > Number(surgeRateMantissa) ? 'Must be smaller than surge rate' : ''
    const surgeRateMantissaErrorMsg = Number(surgeRateMantissa) < Number(minRateMantissa) || Number(surgeRateMantissa) > Number(maxRateMantissa) ? 'Must be between min rate and max rate' : ''
    const maxRateMantissaErrorMsg = Number(maxRateMantissa) < Number(surgeRateMantissa) || Number(maxRateMantissa) > RATE_CEILING ? 'Must be greater than surge rate and lower than 10,000' : ''

    const [buttonText, setButtonText] = useState('Deploy')
    const [txState, setTxState] = useState("ready")
    let actionState = "disabled";
    if(
        txState === "ready" &&
        !isAnyFieldEmpty &&
        !loanTokenErrorMsg &&
        !collateralTokenErrorMsg &&
        !maxCollateralRatioMantissaErrorMsg &&
        !kinkMantissaErrorMsg &&
        !collateralRatioFallDurationErrorMsg &&
        !collateralRatioRecoveryDurationErrorMsg &&
        !minRateMantissaErrorMsg &&
        !surgeRateMantissaErrorMsg &&
        !maxRateMantissaErrorMsg
    ) {
      actionState = "ready"
    } else if(txState === "loading") {
      actionState = "loading"
    } else if(txState === "success") {
      actionState = "success"
    }

    const { chain } = useNetwork()
    const network = chain?.network;
    const networkName = chain?.name
    const networkStartsWithAVowel = ['A', 'E', 'I', 'O', 'U'].includes(networkName[0])
    const factoryAddress = CONSTANTS.networks[network]?.factory;
    const prefix = CONSTANTS.networks[network]?.prefix;
    const factoryAbi = [
        "function deploySurgePool(address _collateralToken, address _loanToken, uint _maxCollateralRatioMantissa, uint _surgeMantissa, uint _collateralRatioFallDuration, uint _collateralRatioRecoveryDuration, uint _minRateMantissa, uint _surgeRateMantissa, uint _maxRateMantissa) external returns (address)"
    ];

    const { data: signer } = useSigner()

    const navigate = useNavigate();

    async function submit(e) {
        e.preventDefault()
        setTxState("loading")
        try {

            const erc20Abi = [
                "function decimals() external view returns (uint8)"
            ]

            const collateralTokenContract = new ethers.Contract(collateralToken, erc20Abi, signer)
            const collateralTokenDecimals = await collateralTokenContract.decimals()

            const loanTokenContract = new ethers.Contract(loanToken, erc20Abi, signer)
            const loanTokenDecimals = await loanTokenContract.decimals()

            const collateralToLoanDecimalsMultiplier = 18 + loanTokenDecimals - collateralTokenDecimals
            const maxCollateralRatioMantissaBN = ethers.utils.parseUnits(maxCollateralRatioMantissa, collateralToLoanDecimalsMultiplier)

            const kinkMantissaBN = ethers.utils.parseUnits(kinkMantissa, 16)

            const minRateMantissaBN = ethers.utils.parseUnits(minRateMantissa, 16)
            const surgeRateMantissaBN = ethers.utils.parseUnits(surgeRateMantissa, 16)
            const maxRateMantissaBN = ethers.utils.parseUnits(maxRateMantissa, 16)

            const collateralRatioFallDurationSeconds = fallDurationUnit === 'Days' ? collateralRatioFallDuration * 86400 : fallDurationUnit === 'Hours' ? collateralRatioFallDuration * 3600 : collateralRatioFallDuration * 60
            const collateralRatioRecoveryDurationSeconds = recoveryDurationUnit === 'Days' ? collateralRatioRecoveryDuration * 86400 : recoveryDurationUnit === 'Hours' ? collateralRatioRecoveryDuration * 3600 : collateralRatioRecoveryDuration * 60

            const factoryContract = new ethers.Contract(factoryAddress, factoryAbi, signer)
            setButtonText('Deploying...')
            const tx = await factoryContract.deploySurgePool(collateralToken, loanToken, maxCollateralRatioMantissaBN, kinkMantissaBN, collateralRatioFallDurationSeconds, collateralRatioRecoveryDurationSeconds, minRateMantissaBN, surgeRateMantissaBN, maxRateMantissaBN);
            const receipt = await tx.wait()
            const poolAddress = ethers.utils.defaultAbiCoder.decode(['uint', 'address'], receipt.logs[0].data)[1]
            addFavorite(poolAddress)
            setTxState("success")
            setButtonText('Deployed')
            setTimeout(() => {
                setTimeout(() => {
                setTxState("ready")
                setButtonText('Deploy')
                const poolNumber = ethers.utils.defaultAbiCoder.decode(['uint'], receipt.logs[0].data)[0]
                navigate(`/pool/${prefix}${poolNumber}`);
                }, 1000)
            }, 2000)
        } catch(e) {
            console.log(e)
            notify("Cannot deploy pool", "Please double-check all fields and try again", "error")
            setTxState("ready")
            setButtonText('Deploy')
        }
      }

  return (
    <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <nav className="mb-4 sm:mb-1 lg:flex lg:items-center lg:justify-between" aria-label="Breadcrumb">
            <div className="flex items-center">
                <a
                    href="/"
                    className="text-xs inline-flex font-medium text-gray-300 rounded-full border-2 border-black-400 hover:bg-black-400 hover:bg-black-400 px-2 py-0.5">
                    <ArrowLeftIcon className="h-4 w-4 flex-shrink-0 text-primary-500" aria-hidden="true" />
                    <span className="ml-0.5">
                        Go back
                        </span>
                </a>
            </div>
        </nav>
        <div className="sm:flex-auto mt-2">
            <h1 className="text-2xl font-bold leading-7 text-white">Create {networkStartsWithAVowel? 'an' :'a'} {networkName} pool</h1>
        </div>
        <div className="bg-black-200 shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6 mt-6">
            <h3 className="text-lg font-medium leading-6 text-white">Choose the parameters at your own risk</h3>
            <div className="mt-2 max-w-xl text-sm text-gray-400">
            <p>
                Once you deploy this pool, no one will be able to change the parameters below.
            </p>
            <br/>
            <p>
                The optimal parameters for each pool vary depending on the underlying tokens market prices, liquidity, volatility, the risk tolerance of the pool participants and other circumstances.
            </p>
            </div>
            <div className="mt-3 text-sm">
                <a href="https://docs.surge-fi.com/product-guide/creating-a-new-pool" className="font-medium text-primary-600 hover:text-primary-500" target="_blank">
                    Learn more about Surge pools
                    <span aria-hidden="true"> &rarr;</span>
                </a>
            </div>
        </div>
        </div>

        <form className="flex h-full flex-col divide-y divide-black-500 bg-black-200 shadow rounded-lg mt-6">
            <div className="h-0 flex-1 overflow-y-auto">
                <div className="flex flex-1 flex-col justify-between">
                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                    <div className="space-y-6 pt-6 pb-5">
                        <div className="pt-2">
                            <h3 className="text-medium font-semibold leading-3 text-white">Pool tokens</h3>
                        </div>
                        <ActionInput
                        value={loanToken} 
                        onChange={(value)=>setLoanToken(value)}
                        label="Loan token address"
                        errorMessage={loanToken !== '' && loanTokenErrorMsg}
                        placeholder="e.g. 0x..."
                        type="text"
                        helperText="The token that will be lent"
                        />

                        <ActionInput
                        value={collateralToken} 
                        onChange={(value)=>setCollateralToken(value)}
                        errorMessage={collateralToken !== '' && collateralTokenErrorMsg}
                        label="Collateral token address"
                        placeholder="e.g. 0x..."
                        type="text"
                        helperText="The token that will be used as collateral"
                        />

                        <div className="pt-4">
                            <h3 className="text-medium font-semibold leading-5 text-white">Collateral ratio model</h3>
                        </div>

                        <ActionInput
                        value={maxCollateralRatioMantissa}
                        onChange={(value)=>setMaxCollateralRatioMantissa(value)}
                        label="Max collateral ratio"
                        errorMessage={maxCollateralRatioMantissa !== '' && maxCollateralRatioMantissaErrorMsg}
                        placeholder="e.g. 1500"
                        type="number"
                        unit="Loan tokens"
                        helperText="Max borrowable lending tokens Per 1 unit of collateral tokens"
                        />
                        <ActionInput
                        value={kinkMantissa}
                        onChange={(value)=>setKinkMantissa(value)}
                        label="Surge utilization rate"
                        errorMessage={kinkMantissa !== '' && kinkMantissaErrorMsg}
                        placeholder="e.g. 80"
                        type="number"
                        unit="%"
                        helperText="The utilization rate at which the collateral ratio starts to fall"
                        />
                        <ActionInput
                        value={collateralRatioFallDuration}
                        onChange={(value)=>setCollateralRatioFallDuration(value)}
                        label="Collateral ratio fall duration"
                        errorMessage={collateralRatioFallDuration !== '' && collateralRatioFallDurationErrorMsg}
                        placeholder="e.g. 1"
                        type="number"
                        defaultOption={fallDurationUnit}
                        unitOptions={["Minutes", "Hours", "Days"]}
                        onOptionChange={setFallDurationUnit}
                        helperText="The duration of the collateral ratio fall from max to zero"
                        />
                        <ActionInput
                        value={collateralRatioRecoveryDuration}
                        onChange={(value)=>setCollateralRatioRecoveryDuration(value)}
                        errorMessage={collateralRatioRecoveryDuration !== '' && collateralRatioRecoveryDurationErrorMsg}
                        label="Collateral ratio recovery duration"
                        placeholder="e.g. 5"
                        type="number"
                        defaultOption={recoveryDurationUnit}
                        onOptionChange={setRecoveryDurationUnit}
                        unitOptions={["Minutes", "Hours", "Days"]}
                        helperText="The duration of the collateral ratio recovery from zero to max"
                        />

                        <div className="pt-3">
                            <h3 className="text-medium font-semibold leading-5 text-white">Interest rate model</h3>
                        </div>

                        <ActionInput
                        value={minRateMantissa}
                        onChange={(value)=>setMinRateMantissa(value)}
                        label="Minimum borrow rate"
                        errorMessage={minRateMantissa !== '' && surgeRateMantissa !== '' && minRateMantissaErrorMsg}
                        placeholder="e.g. 5"
                        type="number"
                        unit="%"
                        helperText="The borrow rate when the utilization rate is zero percent"
                        />
                        <ActionInput
                        value={surgeRateMantissa}
                        onChange={(value)=>setSurgeRateMantissa(value)}
                        label="Surge borrow rate"
                        errorMessage={surgeRateMantissa !== '' && minRateMantissa !== '' && maxRateMantissa !== '' && surgeRateMantissaErrorMsg}
                        placeholder="e.g. 50"
                        type="number"
                        unit="%"
                        helperText="The borrow rate when the utilization rate is at the surge"
                        />
                        <ActionInput
                        value={maxRateMantissa}
                        onChange={(value)=>setMaxRateMantissa(value)}
                        label="Maximum borrow rate"
                        errorMessage={maxRateMantissa !== '' && surgeRateMantissa !== '' && maxRateMantissaErrorMsg}
                        placeholder="e.g. 150"
                        type="number"
                        unit="%"
                        helperText="The borrow rate when the utilization rate is at 100 percent"
                        />
                    </div>
                </div>
                </div>
            </div>
            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                <ActionButton text={buttonText} state={actionState} onClick={submit} />
            </div>
        </form>
        <Notifications show={notification !== null} title={notification?.title} message={notification?.message} type={notification?.type} setOpen={(val) => setNotification(val? notification: null)} /> 
    </div>
  )
}
