import { useContractReads, useContractRead, useNetwork } from 'wagmi';
import { ethers } from 'ethers';
import CONSTANTS from '../constants'
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import formatBN from '../utils/formatBN';
import { formatUnits } from 'ethers/lib/utils.js';
import usePrice from './usePrice'

dayjs.extend(relativeTime)
dayjs.extend(duration)

export default function usePool(poolNumber, account) {
    const { chain } = useNetwork()
    const network = chain?.network;
    const lensAddress = CONSTANTS.networks[network]?.lens;
    const lensAbi = [
        "function getDebtOf(address, address) external view returns (uint)",
        "function getCollateralRatioMantissa(address) external view returns (uint)",
        "function getBorrowRateMantissa(address) external view returns (uint)",
        "function getSupplyRateMantissa(address) external view returns (uint)",
        "function getSuppliedLoanTokens(address) external view returns (uint)",
        "function getInvestmentOf(address, address) external view returns (uint)",
        "function getUtilizationMantissa(address) external view returns (uint)",
        "function getCurrentTotalDebt(address) external view returns (uint)"
    ];

    const lensContract = {
        address: lensAddress,
        abi: lensAbi
    }

    const factoryAddress = CONSTANTS.networks[network]?.factory;
    const factoryAbi = [
        "function pools(uint) external view returns (address)",
        "function feeMantissa() external view returns (uint)"
    ];
    
    const factoryContract = {
        address: factoryAddress,
        abi: factoryAbi
    }
    
    const {data : poolAddress } = useContractRead({
        address: factoryAddress,
        abi: factoryAbi,
        functionName: 'pools',
        args: [poolNumber]
    })
    

    const poolAbi = [
        "function COLLATERAL_TOKEN() external view returns (address)",
        "function LOAN_TOKEN() external view returns (address)",
        "function collateralBalanceOf(address) external view returns (uint)",
        "function MAX_COLLATERAL_RATIO_MANTISSA() external view returns (uint)",
        "function SURGE_MANTISSA() external view returns (uint)",    
        "function COLLATERAL_RATIO_FALL_DURATION() external view returns (uint)",    
        "function COLLATERAL_RATIO_RECOVERY_DURATION() external view returns (uint)",    
        "function MIN_RATE() external view returns (uint)",    
        "function SURGE_RATE() external view returns (uint)",    
        "function MAX_RATE() external view returns (uint)",
        "function lastDepositTimestamp(address) external view returns (uint)"    
    ];

    const poolContract = {
        address: poolAddress,
        abi: poolAbi
    }

    const { data : tokenAddresses } = useContractReads({
        contracts: [
            {
                ...poolContract,
                functionName: 'COLLATERAL_TOKEN'
            },
            {
                ...poolContract,
                functionName: 'LOAN_TOKEN'
            },
        ]
    })

    const tokenAbi = [
        "function symbol() external view returns (string)",
        "function decimals() external view returns (uint8)",
        "function balanceOf(address) external view returns (uint)"
    ];

    const collateralContract = {
        address: tokenAddresses? tokenAddresses[0] : null,
        abi: tokenAbi
    }

    const loanContract = {
        address: tokenAddresses? tokenAddresses[1] : null,
        abi: tokenAbi
    }
    
    const { data: contractData, error, isFetched, refetch, isLoading } = useContractReads({
        //watch: true,
        contracts: [
            // 0
            {
                ...collateralContract,
                functionName: 'symbol'
            },
            // 1
            {
                ...collateralContract,
                functionName: 'decimals'
            },
            // 2
            {
                ...loanContract,
                functionName: 'symbol'
            },
            // 3
            {
                ...loanContract,
                functionName: 'decimals'
            },
            // 4
            {
                ...loanContract,
                functionName: 'balanceOf',
                args: [poolAddress]
            },
            // 5
            {
                ...lensContract,
                functionName: 'getCollateralRatioMantissa',
                args: [poolAddress]
            },
            // 6
            {
                ...lensContract,
                functionName: 'getSuppliedLoanTokens',
                args: [poolAddress]
            },
            // 7
            {
                ...lensContract,
                functionName: 'getBorrowRateMantissa',
                args: [poolAddress]
            },
            // 8
            {
                ...poolContract,
                functionName: 'MAX_COLLATERAL_RATIO_MANTISSA'
            },
            // 9
            {
                ...lensContract,
                functionName: 'getUtilizationMantissa',
                args: [poolAddress]
            },
            // 10
            {
                ...lensContract,
                functionName: 'getSupplyRateMantissa',
                args: [poolAddress]
            },
            //11
            {
                ...poolContract,
                functionName: 'SURGE_MANTISSA'
            },
            //12
            {
                ...poolContract,
                functionName: 'COLLATERAL_RATIO_FALL_DURATION'
            },
            //13
            {
                ...lensContract,
                functionName: 'getCurrentTotalDebt',
                args: [poolAddress]
            },
            //14
            {
                ...lensContract,
                functionName: 'getInvestmentOf',
                args: [poolAddress, account]
            }, 
            //15   
            {  
                ...lensContract,
                functionName: 'getDebtOf',
                args: [poolAddress, account]
            },
            //16
            {
                ...poolContract,
                functionName: 'collateralBalanceOf',
                args: [account]
            },
            //17
            {
                ...poolContract,
                functionName: 'COLLATERAL_RATIO_RECOVERY_DURATION'
            },
            //18
            {
                ...loanContract,
                functionName: 'balanceOf',
                args: [account]
            },
            // 19
            {
                ...collateralContract,
                functionName: 'balanceOf',
                args: [account]
            },
            // 20
            {
                ...poolContract,
                functionName: 'MIN_RATE'
            },
            // 21
            {
                ...poolContract,
                functionName: 'SURGE_RATE'
            },
            // 22
            {
                ...poolContract,
                functionName: 'MAX_RATE'
            },
            // 23
            {
                ...factoryContract,
                functionName: 'feeMantissa',
            },
            // 24
            {
                ...poolContract,
                functionName: 'lastDepositTimestamp',
                args: [account]
            },
        ]
    })


    const collateralPrice = usePrice(collateralContract.address)
    const loanPrice = usePrice(loanContract.address)
    if(!contractData || !contractData[1] || !isFetched || error) return { isLoading }
    const data = {}

    const BN = ethers.BigNumber.from;
    data.isLoading = isLoading;
    data.collateralSymbol = contractData[0]
    data.loanSymbol = contractData[2]
    data.liquidity = formatBN(contractData[4], contractData[3])
    data.liquidityRaw = contractData[4]
    data.collateralRatio = formatBN(contractData[5], BN(18).add(contractData[3]).sub(contractData[1]))
    data.collateralRatioRaw = contractData[5];
    data.suppliedLoanTokens = formatBN(contractData[6], contractData[3])
    data.suppliedLoanTokensRaw = contractData[6]
    data.borrowRate = formatBN(contractData[7], 16)
    data.supplyRate = formatBN(contractData[10], 16)
    data.maxCollateralRatio = formatUnits(contractData[8], BN(18).add(contractData[3]).sub(contractData[1]))
    data.utilization = formatBN(contractData[9], 16)
    data.kink = formatBN(contractData[11], 16)
    data.kinkRaw = contractData[11]
    data.collateralRatioFallSpeed = formatBN(contractData[8].div(contractData[12]).mul(3600), BN(18).add(contractData[3]).sub(contractData[1])) // per hour
    data.collateralRatioRecoverySpeed = formatBN(contractData[8].div(contractData[17]).mul(3600), BN(18).add(contractData[3]).sub(contractData[1])) // per hour
    data.totalDebt = formatBN(contractData[13], contractData[3])
    data.totalDebtRaw = contractData[13]
    data.investment = formatBN(contractData[14], contractData[3])
    data.investmentRaw = contractData[14]
    data.debt = formatBN(contractData[15], contractData[3])
    if(isNaN(data.debt)) data.debt = 0
    data.debtRaw = contractData[15]
    data.collateralBalance = formatBN(contractData[16], contractData[1])
    data.collateralBalanceRaw = contractData[16]
    data.collateralDecimals = contractData[1]
    data.loanDecimals = contractData[3]
    data.loanAddress = loanContract.address
    data.collateralAddress = collateralContract.address
    data.address = poolAddress
    data.refetch = refetch
    data.userLoanBalance = formatBN(contractData[18], contractData[3])
    data.userCollateralBalance = formatBN(contractData[19], contractData[1])
    data.userLoanBalanceRaw = contractData[18]
    data.userCollateralBalanceRaw = contractData[19]
    data.borrowLimitRaw = data.collateralBalanceRaw.mul(data.collateralRatioRaw).div(ethers.constants.WeiPerEther)
    data.borrowLimit = formatBN(data.borrowLimitRaw, contractData[3])
    data.borrowMaxRaw = data.borrowLimitRaw.gt(data.debtRaw) ? data.borrowLimitRaw.sub(data.debtRaw): BN(0)
    data.globalBorrowMaxRaw = data.suppliedLoanTokensRaw.mul(data.kinkRaw).div(ethers.constants.WeiPerEther).sub(data.debtRaw)
    if(data.debtRaw.eq(0)) {
        data.maxRemoveCollateralRaw = data.collateralBalanceRaw
    } else if(data.collateralRatioRaw.eq(0)) {
        data.maxRemoveCollateralRaw = BN(0)
    } else {
        data.maxRemoveCollateralRaw = data.collateralBalanceRaw.sub(data.debtRaw.mul(ethers.constants.WeiPerEther).div(data.collateralRatioRaw))
        if(data.maxRemoveCollateralRaw.gt(100000)) data.maxRemoveCollateralRaw = data.maxRemoveCollateralRaw.sub(data.maxRemoveCollateralRaw.div(100000)); // fix rounding error
    }
    data.collateralRatioFallDuration = dayjs.duration(contractData[12].mul(1000).toNumber()).humanize()
    data.collateralRatioRecoveryDuration = dayjs.duration(contractData[17].mul(1000).toNumber()).humanize()
    data.minRate = formatBN(contractData[20], 16)
    data.minRateRaw = contractData[20]
    data.surgeRate = formatBN(contractData[21], 16)
    data.surgeRateRaw = contractData[21]
    data.maxRate = formatBN(contractData[22], 16)
    data.maxRateRaw = contractData[22]
    data.feeRaw = contractData[23]
    data.lastDepositTimestamp = Number(contractData[24].toString())
    data.collateralPrice = collateralPrice
    data.loanPrice = loanPrice
    if(collateralPrice && loanPrice) {
        data.collateralRatioPercent = data.collateralRatio * loanPrice / collateralPrice * 100
    }
    return data
}