import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useAccount, useContractRead, useSigner } from 'wagmi'
import { ethers } from 'ethers'
import ActionButton from './ActionButton'
import ActionInput from './ActionInput'
import numeral from 'numeral'
import formatBN from '../utils/formatBN'

export default function Repay({open, setOpen, address, loanDecimals, loanAddress, onReceipt, loanSymbol, debtRaw, debt, collateralRatio, collateralBalance, userLoanBalanceRaw}) {
  const [amount, setAmount] = useState('')

  let amountErrorMessage = '';
  if(amount) {
    if(amount.lte(0)) amountErrorMessage = 'Amount must be greater than 0'
    else if(amount.gt(debtRaw)) amountErrorMessage = 'Insufficient debt'
  }

  const [buttonText, setButtonText] = useState('Repay')
  const [txState, setTxState] = useState("ready")
  let actionState = "disabled";
  if(txState === "ready" && amount && !amountErrorMessage) {
    actionState = "ready"
  } else if(txState === "loading") {
    actionState = "loading"
  } else if(txState === "success") {
    actionState = "success"
  }

  const [repayAllButtonText, setRepayAllButtonText] = useState('Repay all')
  const [repayAllTxState, setRepayAllTxState] = useState("ready")
  let repayAllActionState = "disabled";
  
  if(repayAllTxState === "ready" && debtRaw.gt(0) && userLoanBalanceRaw.gte(debtRaw)) {
    repayAllActionState = "ready"
  } else if(repayAllTxState === "loading") {
    repayAllActionState = "loading"
  } else if(repayAllTxState === "success") {
    repayAllActionState = "success"
  }

  const { address :account } = useAccount()
    function setAmountBigNumber(value) {
      try {
        setAmount(ethers.utils.parseUnits(value, loanDecimals))
      } catch(e) {
        setAmount('')
      }
    }

    const addedDebt = !amount || amount.gt(debtRaw)? 0 : formatBN(amount, loanDecimals)
    const newDebt = numeral(Number(debt) - Number(addedDebt)).format('0.[00]')

    const borrowLimit = numeral(collateralRatio * collateralBalance).format('0.[00]')
    const borrowLimitPct = borrowLimit == 0? 0: numeral(newDebt * 100 / borrowLimit).format('0.[00]')

    const poolAbi = [
        "function repay(address to, uint) external"
    ];

    const loanAbi = [
        "function allowance(address, address) external view returns (uint)",
        "function approve(address, uint) external returns (bool)"
    ];

    const { data: allowanceData, refetch: allowanceRefetch } = useContractRead({
      address: loanAddress,
      abi: loanAbi,
      functionName: 'allowance',
      args: [account, address]
  })

    const { data: signer } = useSigner()

    async function submit(e) {
      e.preventDefault()
      setTxState("loading")
      try {
        const poolContract = new ethers.Contract(address, poolAbi, signer)
        const loanContract = new ethers.Contract(loanAddress, loanAbi, signer)
          if(allowanceData.lt(amount)) {
              setButtonText('Approving...')
              const approveTx = await loanContract.approve(address, ethers.constants.MaxUint256);
              await approveTx.wait()
              await allowanceRefetch()
          }
          setButtonText('Repaying...')
          const tx = await poolContract.repay(account, amount);
          await tx.wait()
          onReceipt()
          setTxState("success")
          setButtonText('Repaid')
          setAmount('')
          setTimeout(() => {
            setTimeout(() => {
              setOpen(false)
              setTxState("ready")
              setButtonText('Repay')
            }, 1000)
          }, 2000)
        } catch(e) {
          setTxState("ready")
          setButtonText('Repay')
      }
    }

    async function repayAll(e) {
      e.preventDefault()
      setRepayAllTxState("loading")
      try {
        const poolContract = new ethers.Contract(address, poolAbi, signer)
        const loanContract = new ethers.Contract(loanAddress, loanAbi, signer)
          if(allowanceData.lt(userLoanBalanceRaw)) {
            setRepayAllButtonText('Approving...')
              const approveTx = await loanContract.approve(address, ethers.constants.MaxUint256);
              await approveTx.wait()
              await allowanceRefetch()
          }
          setRepayAllButtonText('Repaying all...')
          const tx = await poolContract.repay(account, ethers.constants.MaxUint256);
          await tx.wait()
          onReceipt()
          setRepayAllTxState("success")
          setRepayAllButtonText('Repaid all')
          setAmount('')
          setTimeout(() => {
            setTimeout(() => {
              setOpen(false)
              setRepayAllTxState("ready")
              setRepayAllButtonText('Repay all')
            }, 1000)
          }, 2000)
        } catch(e) {
          setRepayAllTxState("ready")
          setRepayAllButtonText('Repay all')
      }
    }

    function close() {
      setAmount('')
      setOpen(false)
    }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
      <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>


        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-black-500 bg-black-200 shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-black-300 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">Repay tokens</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={close}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-400">
                            Repay your loan to reduce your borrow limit usage
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                          <ActionInput
                            max={ethers.utils.formatUnits(debtRaw, loanDecimals)}
                            label="Amount"
                            placeholder={"e.g. " + debt}
                            unit={loanSymbol}
                            onChange={setAmountBigNumber}
                            errorMessage={amountErrorMessage}
                            helperText="The amount of tokens you want to repay."
                          />
                          </div>
                          <div>
                              <div className="grid-cols-2 flex">
                                  <p className="font-medium leading-6 text-gray-400 flex-auto">Your new borrow limit usage</p>
                                  <p className="text-sm font-bold text-white flex-auto text-right">{ borrowLimitPct }%</p>
                              </div>
                              <div className="mt-3" aria-hidden="true">
                                  <div className="overflow-hidden rounded-full bg-black-400">
                                  <div className="h-2 rounded-full bg-primary-600 text-right" style={{ width: `${borrowLimitPct}%` }} />
                                  </div>
                                  
                                  <div className="mt-3 hidden grid-cols-2 text-sm font-medium text-gray-400 sm:grid">
                                      <div className="text-primary-500">{ newDebt } {loanSymbol }</div>
                                      <div className="text-right">{borrowLimit} {loanSymbol}</div>
                                  </div>
                              </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-black-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        onClick={close}
                      >
                        Close
                      </button>
                      <ActionButton
                        onClick={repayAll}
                        text={repayAllButtonText}
                        state={repayAllActionState}
                      />
                      <ActionButton
                        onClick={submit}
                        text={buttonText}
                        state={actionState}
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
