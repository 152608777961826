import { useProvider, useNetwork } from 'wagmi';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import CONSTANTS from '../constants'
import formatBN from '../utils/formatBN';

export default function usePoolPositions({address, collateralRatioRaw, loanDecimals, collateralDecimals}) {
    const { chain } = useNetwork()
    const network = chain?.network;
    const lensAddress = CONSTANTS.networks[network]?.lens;
    const lensAbi = [
        "function getDebtOf(address, address) external view returns (uint)",
    ];
    const poolAbi = [
        "event Borrow(address indexed user, uint amount)",
        "function collateralBalanceOf(address) external view returns (uint)"
    ];

    const provider = useProvider()

    const lensContract = new ethers.Contract(lensAddress, lensAbi, provider)
    const poolContract = new ethers.Contract(address, poolAbi, provider)

    const [positions, setPositions] = useState([]);
    const BN = ethers.BigNumber.from;

    useEffect(() => {
        poolContract.queryFilter(poolContract.filters.Borrow()).then(events => {
            events
            // filter duplicates
            .filter((event, index, self) =>
                index === self.findIndex((t) => (
                    t.args[0] === event.args[0]
                ))
            )
            .forEach(async event => {
                const borrower = event.args[0];
                const debtRaw = await lensContract.getDebtOf(address, borrower);
                if (debtRaw.gt(0)) {
                    const collateralBalanceRaw = await poolContract.collateralBalanceOf(borrower);
                    const userCollateralRatioRaw = collateralBalanceRaw.eq(0)? 0: debtRaw.mul(ethers.constants.WeiPerEther).div(collateralBalanceRaw);
                    const debt = formatBN(debtRaw, loanDecimals)
                    const collateralBalance = formatBN(collateralBalanceRaw, collateralDecimals)
                    const collateralRatio = formatBN(userCollateralRatioRaw, BN(18).add(loanDecimals).sub(collateralDecimals))
                    const liquidatable = userCollateralRatioRaw.gt(collateralRatioRaw);
                    setPositions(old => [...old, {borrower, debt, debtRaw, collateralBalance, collateralRatio, liquidatable}])
                }
            })
        })
    }, [])

    return positions
    .sort((a, b) => b.collateralRatio - a.collateralRatio)
    .filter((position, index, self) =>
        index === self.findIndex((t) => (
            t.borrower === position.borrower
        ))
    )
   ;
}