import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PoolActions({ setLendOpen, setAddCollateralOpen, setBorrowOpen, setWithdrawOpen, setRepayOpen, setRemoveCollateralOpen, label, showLend, showAddCollateral, showBorrow, showRepay, showWithdraw, showRemoveCollateral }) {
  return (
    <>
    <Menu as="div" className="relative text-left ml-6">
      <div>
        <Menu.Button className="inline-flex w-32 justify-end rounded-md border border-transparent bg-black-400 px-4 py-2 text-sm font-medium text-white hover:bg-black-500">
          {label}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="cursor-pointer absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-black-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {showLend && <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => setLendOpen(true)}
                  className={classNames(
                    active ? 'bg-black-400 text-white' : 'text-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Supply
                </a>
              )}
            </Menu.Item>}
            {showWithdraw && <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => setWithdrawOpen(true)}
                  className={classNames(
                    active ? 'bg-black-400 text-white' : 'text-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Withdraw
                </a>
              )}
            </Menu.Item>}
            {showAddCollateral && <Menu.Item>
              {({ active }) => (
                <a
                onClick={() => setAddCollateralOpen(true)}
                className={classNames(
                    active ? 'bg-black-400 text-white' : 'text-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Add collateral
                </a>
              )}
            </Menu.Item>}
            {showRemoveCollateral && <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => setRemoveCollateralOpen(true)}
                  className={classNames(
                    active ? 'bg-black-400 text-white' : 'text-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Remove collateral
                </a>
              )}
            </Menu.Item>}
            {showBorrow && <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => setBorrowOpen(true)}
                  className={classNames(
                    active ? 'bg-black-400 text-white' : 'text-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Borrow
                </a>
              )}
            </Menu.Item>}
            {showRepay && <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => setRepayOpen(true)}
                  className={classNames(
                    active ? 'bg-black-400 text-white' : 'text-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Repay
                </a>
              )}
            </Menu.Item>}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    </>
  )
}
