import PoolActions from "../components/PoolActions";
import numeral from "numeral";
import { ethers } from "ethers";

export default function UserPosition( {
    debtRaw,
    collateralBalanceRaw,
    collateralSymbol,
    loanSymbol,
    investmentRaw,
    supplyRate,
    borrowRate,
    suppliedLoanTokensRaw,
    collateralRatioRaw,
    setLendOpen,
    setWithdrawOpen,
    setBorrowOpen,
    setRepayOpen,
    setAddCollateralOpen,
    setRemoveCollateralOpen,
    loanDecimals,
    collateralDecimals
}) {
    const BN = ethers.BigNumber.from
    const collateralBalance = Number(ethers.utils.formatUnits(collateralBalanceRaw, collateralDecimals))
    const borrowLimit = Number(ethers.utils.formatUnits(collateralRatioRaw, BN(18).add(loanDecimals).sub(collateralDecimals))) * collateralBalance
    const debt = Number(ethers.utils.formatUnits(debtRaw, loanDecimals))
    const borrowLimitPct = borrowLimit === 0? 0: debt * 100 / borrowLimit
    const userCollateralRatio = collateralBalance === 0? 0: debt / collateralBalance
    const investment = Number(ethers.utils.formatUnits(investmentRaw, loanDecimals))
    const suppliedLoanTokens = Number(ethers.utils.formatUnits(suppliedLoanTokensRaw, loanDecimals))
    const userPoolShare = suppliedLoanTokens === 0? 0: investment * 100 / suppliedLoanTokens
    const monthlyProfit = supplyRate * investment / 100 / 12
    const monthlyCost = borrowRate * debt / 100 / 12
    return (
    <div>
        { collateralBalance > 0 && <div className="overflow-hidden bg-black-200 shadow rounded-lg px-4 py-5 sm:px-6 mb-5">
            <div className="grid-cols-2 flex">
                <p className="font-medium leading-6 text-white flex-auto">Your borrow limit usage</p>
                <p className="text-sm font-medium text-white font-bold flex-auto text-right">{ borrowLimitPct === 0 || borrowLimitPct >= 0.01 ? numeral(borrowLimitPct).format('0a,0.[00]') : "<0.001" }%</p>
            </div>
            <div className="mt-3" aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-black-400">
                <div className="h-2 rounded-full bg-primary-600 text-right" style={{ width: `${borrowLimitPct}%` }} />
                </div>
                <div className="mt-3 hidden grid-cols-2 text-sm font-medium sm:grid">
                    <div className="text-primary-500">{ debt === 0 || debt >= 0.01 ? numeral(debt).format('0a,0.[00]') : "<0.001"} {loanSymbol }</div>
                    <div className="text-right text-gray-400">{ borrowLimit } {loanSymbol}</div>
                </div>
            </div>
        </div>}

        <div className="bg-black-200 shadow rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <div className="text-lg font-medium leading-6 text-white mb-4">
                Your info
                </div>
                <dl className="grid grid-cols-2 gap-x-2 gap-y-8 sm:grid-cols-12">
                    <div className="sm:col-span-7 col-span-1">
                        <dt className="text-sm font-medium text-gray-400">Supplied</dt>
                        <dd className="mt-1 text-sm text-white">{ investment === 0 || investment >= 0.001 ? numeral(investment).format('0a,0.[00]') : "<0.001" } { loanSymbol } {monthlyProfit > 0.001 && <span className="text-green-500">+{numeral(monthlyProfit).format('0a,0.[00]')}/month</span>}</dd>
                    </div>
                    <div className="sm:col-span-5 col-span-1 pt-1">
                        <div className="text-sm font-medium text-gray-500 float-right">
                            <PoolActions
                                setLendOpen={setLendOpen}
                                setWithdrawOpen={setWithdrawOpen}
                                showLend={true}
                                showWithdraw={true}
                                label="Lending"
                            />
                        </div>
                    </div>
                </dl>
                <dl className="grid gap-x-2 gap-y-8 sm:grid-cols-12 grid-cols-2 mt-6">
                    <div className="sm:col-span-7 col-span-1">
                        <dt className="text-sm font-medium text-gray-400">Collateral</dt>
                        <dd className="mt-1 text-sm text-white">{ collateralBalance === 0 || collateralBalance >= 0.001 ? numeral(collateralBalance).format('0a,0.[00]') : "<0.001" } { collateralSymbol }</dd>
                    </div>
                    <div className="sm:col-span-5 col-span-1 pt-1">
                        <div className="text-sm font-medium text-gray-500 float-right">
                            <PoolActions
                                setAddCollateralOpen={setAddCollateralOpen}
                                setRemoveCollateralOpen={setRemoveCollateralOpen}
                                showAddCollateral={true}
                                showRemoveCollateral={true}
                                label="Collateral"
                            />
                        </div>
                    </div>
                </dl>
                <dl className="grid gap-x-2 gap-y-8 sm:grid-cols-12 grid-cols-2 mt-6">
                    <div className="sm:col-span-7 col-span-1">
                        <dt className="text-sm font-medium text-gray-400">Borrowed</dt>
                        <dd className="mt-1 text-sm text-white">{ debt === 0 || debt >= 0.001 ? numeral(debt).format('0a,0.[00]') : "<0.001" } { loanSymbol } {monthlyCost > 0.001 && <span className="text-red-500">+{numeral(monthlyCost).format('0a,0.[00]')}/month</span>}</dd>
                    </div>
                    <div className="sm:col-span-5 pt-1 col-span-1">
                        <div className="text-sm font-medium text-gray-500 float-right">
                            <PoolActions
                                setBorrowOpen={setBorrowOpen}
                                setRepayOpen={setRepayOpen}
                                showBorrow={true}
                                showRepay={true}
                                label="Borrowing"
                            />
                        </div>
                    </div>
                </dl>
            </div>
        </div>

        { (debt > 0 || investment > 0) && <div className="overflow-hidden bg-black-200 shadow rounded-lg mt-5">

            <div className="px-4 py-5 sm:px-6">
                <div className="text-lg font-medium leading-6 text-white mb-4">
                    Your position details
                </div>
                <dl className="gap-x-4 gap-y-8 sm:grid-cols-2">
                    {userCollateralRatio > 0 && <div className="mt-3">
                        <dt className="text-sm font-medium text-gray-400">Your collateral ratio ({loanSymbol}/{collateralSymbol})</dt>
                        <dd className="mt-1 text-sm text-white">{numeral(userCollateralRatio).format('0a,0.[00]')} : 1</dd>
                    </div>}
                    {userPoolShare > 0 && <div className="mt-3">
                        <dt className="text-sm font-medium text-gray-400">Pool share</dt>
                        <dd className="mt-1 text-sm text-white">{ numeral(userPoolShare).format('0a,0.[00]') }%</dd>
                    </div>}
                </dl>
            </div>
        </div>}
    </div>
  )
}
