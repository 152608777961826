import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useSigner } from 'wagmi'
import { ethers } from 'ethers'
import ActionButton from './ActionButton'
import ActionInput from './ActionInput'
import numeral from 'numeral'

export default function Withdraw({open, setOpen, address, loanDecimals, onReceipt, loanSymbol, userDepositRaw, userDeposit, liquidityRaw, lastDepositTimestamp}) {

  const [amount, setAmount] = useState('')
  const timeInSeconds = (Date.now() / 1000)
  const lastDepositPlus30mins = lastDepositTimestamp + 1800

  let timeLeft = parseInt((lastDepositPlus30mins - timeInSeconds) / 60)

  let amountErrorMessage = '';
  if(amount) {
    if(amount.lte(0)) amountErrorMessage = 'Amount must be greater than 0'
    else if(amount.gt(userDepositRaw)) amountErrorMessage = 'Insufficient supply balance'
    else if(amount.gt(liquidityRaw)) amountErrorMessage = 'Insufficient pool liquidity. Withdraw less or try again later.'
    else if(timeInSeconds < lastDepositPlus30mins) amountErrorMessage = `You must wait 30 minutes after depositing to withdraw. (${timeLeft} minutes left)`
  }

  const [buttonText, setButtonText] = useState('Withdraw')
  const [txState, setTxState] = useState("ready")
  let actionState = "disabled";
  if(txState === "ready" && amount && !amountErrorMessage) {
    actionState = "ready"
  } else if(txState === "loading") {
    actionState = "loading"
  } else if(txState === "success") {
    actionState = "success"
  }

  const [withdrawAllButtonText, setWithdrawAllButtonText] = useState('Withdraw all')
  const [withdrawAllTxState, setWithdrawAllTxState] = useState("ready")
  let withdrawAllActionState = "disabled";
  
  if(withdrawAllTxState === "ready" && userDepositRaw.gt(0) && liquidityRaw.gte(userDepositRaw)) {
    withdrawAllActionState = "ready"
  } else if(withdrawAllTxState === "loading") {
    withdrawAllActionState = "loading"
  } else if(withdrawAllTxState === "success") {
    withdrawAllActionState = "success"  }


    function setAmountBigNumber(value) {
      try {
        setAmount(ethers.utils.parseUnits(value, loanDecimals))
      } catch(e) {
        setAmount('')
      }
    }

    const poolAbi = [
        "function withdraw(uint) external"
    ];

    const { data: signer } = useSigner()

    async function submit(e) {
      e.preventDefault()
      setTxState("loading")
      try {
        const poolContract = new ethers.Contract(address, poolAbi, signer)
          setButtonText('Withdrawing...')
          const tx = await poolContract.withdraw(amount);
          await tx.wait()
          onReceipt()
          setTxState("success")
          setButtonText('Withdrawn')
          setAmount('')
          setTimeout(() => {
            setTimeout(() => {
              setOpen(false)
              setTxState("ready")
              setButtonText('Withdraw')
            }, 1000)
          }, 2000)
        } catch(e) {
          setTxState("ready")
          setButtonText('Withdraw')
      }
    }
    async function withdrawAll(e) {
      e.preventDefault()
      setWithdrawAllTxState("loading")
      try {
        const poolContract = new ethers.Contract(address, poolAbi, signer)
          setWithdrawAllButtonText('Withdrawing all...')
          const tx = await poolContract.withdraw(ethers.constants.MaxUint256);
          await tx.wait()
          onReceipt()
          setWithdrawAllTxState("success")
          setWithdrawAllButtonText('Withdrawn all')
          setAmount('')
          setTimeout(() => {
            setTimeout(() => {
              setOpen(false)
              setWithdrawAllTxState("ready")
              setWithdrawAllButtonText('Withdraw all')
            }, 1000)
          }, 2000)
        } catch(e) {
          setWithdrawAllTxState("ready")
          setWithdrawAllButtonText('Withdraw all')
      }
    }

    function close() {
      setAmount('')
      setOpen(false)
    }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
      <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>


        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-black-500 bg-black-200 shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-black-300 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">Withdraw tokens</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={close}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-400">
                            Withdraw tokens from the pool
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-black-600 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                          <ActionInput
                            max={ethers.utils.formatUnits(userDepositRaw, loanDecimals)}
                            label="Amount"
                            placeholder={"available: " + numeral(ethers.utils.formatUnits(userDepositRaw, loanDecimals)).format('0,0.00')}
                            unit={loanSymbol}
                            onChange={setAmountBigNumber}
                            errorMessage={amountErrorMessage}
                            helperText="The amount of tokens you want to withdraw."
                          />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-black-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        onClick={close}
                      >
                        Close
                      </button>
                      <ActionButton
                        onClick={withdrawAll}
                        text={withdrawAllButtonText}
                        state={withdrawAllActionState}
                      />
                      <ActionButton
                        onClick={submit}
                        text={buttonText}
                        state={actionState}
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
