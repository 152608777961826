import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useSigner } from 'wagmi'
import { ethers } from 'ethers'
import ActionButton from './ActionButton'
import ActionInput from './ActionInput'
import numeral from 'numeral'
import formatBN from '../utils/formatBN'

export default function Borrow({open, setOpen, address, totalDebtRaw, suppliedRaw, surgeMantissaRaw, minRateRaw, surgeRateRaw, maxRateRaw, loanDecimals, collateralDecimals, onReceipt, loanSymbol, borrowMaxRaw, collateralBalanceRaw, collateralRatioRaw, debtRaw, globalBorrowMaxRaw}) {
  const [amount, setAmount] = useState('')

  let amountErrorMessage = '';
  if(amount) {
    if(amount.lte(0)) amountErrorMessage = 'Amount must be greater than 0'
    else if(amount.gt(borrowMaxRaw)) amountErrorMessage = 'Insufficient borrow limit'
    else if(amount.gt(globalBorrowMaxRaw)) amountErrorMessage = 'Your loan exceeds the pool borrow limit. Borrow less or try again later.'
  }

  const [buttonText, setButtonText] = useState('Borrow')
  const [txState, setTxState] = useState("ready")
  let actionState = "disabled";
  if(txState === "ready" && amount && !amountErrorMessage) {
    actionState = "ready"
  } else if(txState === "loading") {
    actionState = "loading"
  } else if(txState === "success") {
    actionState = "success"
  }
    function setAmountBigNumber(value) {
      try {
        setAmount(ethers.utils.parseUnits(value, loanDecimals))
      } catch(e) {
        setAmount('')
      }
    }    

    const BN = ethers.BigNumber.from

    const addedDebt = Number(ethers.utils.formatUnits(amount || 0, loanDecimals))
    const newDebt = Number(ethers.utils.formatUnits(debtRaw, loanDecimals)) + Number(addedDebt)

    const collateralRatio = Number(ethers.utils.formatUnits(collateralRatioRaw, BN(18).add(loanDecimals).sub(collateralDecimals)))
    const collateralBalance = Number(ethers.utils.formatUnits(collateralBalanceRaw, collateralDecimals))
    const borrowLimit = collateralRatio * collateralBalance
    const borrowLimitPct = borrowLimit == 0? 0: newDebt * 100 / borrowLimit

    function getExpectedBorrowRate(utilizationMantissa, surgeMantissa, minRateMantissa, surgeRateMantissa, maxRateMantissa){
      const util = BN(utilizationMantissa)
      const surgeThreshold = BN(surgeMantissa)
      const minRate = BN(minRateMantissa)
      const surgeRate = BN(surgeRateMantissa)
      const maxRate = BN(maxRateMantissa)

      if(util.lte(surgeThreshold)){
        return surgeRate.sub(minRate).mul(util).div(surgeThreshold).add(minRate)
      } else {
        return maxRate.sub(surgeRate).mul(util.sub(surgeThreshold)).div(BN(10).pow(18).sub(surgeThreshold)).add(surgeRate)
      }
    }

    function getUtilizationMantissa(totalDebt, supplied){
      if (supplied.eq(0)) return BN(0)
      return BN(totalDebt).mul(BN(10).pow(18)).div(BN(supplied))
    }

    const expectedTotalDebtRaw = amount? totalDebtRaw.add(amount): totalDebtRaw

    let utilizationMantissa = getUtilizationMantissa(expectedTotalDebtRaw, suppliedRaw)

    if(utilizationMantissa.gt(BN(10).pow(18))) utilizationMantissa = BN(10).pow(18)

    const expectedBorrowAPR = formatBN(getExpectedBorrowRate(utilizationMantissa, surgeMantissaRaw, minRateRaw, surgeRateRaw, maxRateRaw).mul(100))

    const poolAbi = [
        "function borrow(uint) external"
    ];

    const { data: signer } = useSigner()

    async function submit(e) {
      e.preventDefault()
      setTxState("loading")
      try {
        const poolContract = new ethers.Contract(address, poolAbi, signer)
          setButtonText('Borrowing...')
          const tx = await poolContract.borrow(amount);
          await tx.wait()
          onReceipt()
          setTxState("success")
          setButtonText('Borrowed')
          setAmount('')
          setTimeout(() => {
            setTimeout(() => {
              setOpen(false)
              setTxState("ready")
              setButtonText('Borrow')
            }, 1000)
          }, 2000)
        } catch(e) {
          setTxState("ready")
          setButtonText('Borrow')
      }
    }

    function close() {
      setAmount('')
      setOpen(false)
    }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
      <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>


        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-black-500 bg-black-200 shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-black-300 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">Borrow tokens</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={close}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-400">
                            Borrow tokens from the pool
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                          <ActionInput
                            max={ethers.utils.formatUnits(borrowMaxRaw, loanDecimals)}
                            label="Amount"
                            placeholder={"available: " + numeral(ethers.utils.formatUnits(borrowMaxRaw, loanDecimals)).format("0,0.000")}
                            unit={loanSymbol}
                            onChange={setAmountBigNumber}
                            errorMessage={amountErrorMessage}
                            helperText="The amount of tokens you want to borrow."
                          />
                          </div>
                          <div class="pb-5">
                              <div className="grid-cols-2 flex">
                                  <p className="font-medium leading-6 text-gray-400 flex-auto">Your new borrow limit usage</p>
                                  <p className="text-lg font-bold text-white flex-auto text-right">{ numeral(borrowLimitPct).format("0a,0.[00]") }%</p>
                              </div>
                              <div className="mt-3" aria-hidden="true">
                                  <div className="overflow-hidden rounded-full bg-black-400">
                                  <div className="h-2 rounded-full bg-primary-600 text-right" style={{ width: `${borrowLimitPct}%` }} />
                                  </div>
                                  
                                  <div className="mt-3 hidden grid-cols-2 text-sm font-medium text-gray-400 sm:grid">
                                      <div className="text-primary-500">{ numeral(newDebt).format("0a,0.[00]") } {loanSymbol }</div>
                                      <div className="text-right">{numeral(borrowLimit).format("0a,0.[00]")} {loanSymbol}</div>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div className="grid-cols-2 flex">
                                  <p className="font-medium text-gray-400 leading-6 text-white flex-auto">Expected borrow APR</p>
                              </div>
                              <div className="mt-1 text-white">
                                <p>
                                  {expectedBorrowAPR}%
                                </p>  
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-black-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        onClick={close}
                      >
                        Close
                      </button>
                      <ActionButton
                        onClick={submit}
                        text={buttonText}
                        state={actionState}
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
