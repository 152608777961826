import { ConnectKitButton } from "connectkit";
import styled from "styled-components";

const StyledButton = styled.button`
  cursor: pointer;
  padding: 12px 24px;
  color: #0A0B0D;
  background: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0.5rem;
  &:hover {
    background: #E5E7EB;
  }
  &:active {
    background: #D1D5DB;
  }
`;

export const ConnectButton = () => {
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress, ensName }) => {
        return (
          <StyledButton onClick={show}>
            {isConnected ? ensName ?? truncatedAddress : "Connect your wallet"}
          </StyledButton>
        );
      }}
    </ConnectKitButton.Custom>
  );
};