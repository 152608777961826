import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";
import { WagmiConfig, createClient } from "wagmi";
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import * as wagmiChains from "wagmi/chains";

import Root from "./routes/Root";
import Pool from "./routes/Pool";
import FAQs from "./routes/FAQs";
import Create from "./routes/Create";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CONSTANTS from "./constants.json"


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />
  },
  {
    path: "/create",
    element: <Create />
  },
  {
    path: "/faqs",
    element: <FAQs />
  },
  {
    path: `/pool/:poolName`,
    element: <Pool />,
    loader: ({ params }) => {
      const { poolName } = params;
      if(poolName.charAt(0).toLowerCase() !== poolName.charAt(0).toUpperCase() && poolName.charAt(0) === poolName.charAt(0).toLowerCase()) {
        // redirect to uppercase
        return redirect(`/pool/${poolName.charAt(0).toUpperCase()}${poolName.slice(1)}`)
      }
      return null;
    }
  }
]);

const chains = Object.keys(CONSTANTS.networks).map(network => wagmiChains[network])

const client = createClient(
  getDefaultClient({
    appName: "Surge",
    alchemyId: process.env.REACT_APP_ALCHEMY_API,
    walletConnectProjectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
    chains
  }),
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <WagmiConfig client={client}>
  <ConnectKitProvider theme="auto" mode="dark">
  <div className="min-h-full flex flex-col h-screen">
    <Navbar/>
    {/* <div className="bg-main"></div> */}
    <div className="py-6 flex-grow">
      <RouterProvider router={router} />
    </div>
    <Footer/>
  </div>
  </ConnectKitProvider>
  </WagmiConfig>
);