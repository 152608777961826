import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
export default function useFavorites() {
    
    const [favorites, setFavorites] = useState(JSON.parse(window.localStorage.getItem("favorite-pools")) || [])

    const addFavorite = (address) => {
        const event = new CustomEvent('add-favorite', { detail: { address } })
        window.dispatchEvent(event)
    }
    
    const removeFavorite = (address) => {
        const event = new CustomEvent('remove-favorite', { detail: { address } })
        window.dispatchEvent(event)
    }

    useEffect(() => {
        window.addEventListener("add-favorite", (e) => {
            let currentfavorites = JSON.parse(window.localStorage.getItem("favorite-pools")) || []
            currentfavorites.push(e.detail.address)
            currentfavorites = [...new Set(currentfavorites)]
            window.localStorage.setItem("favorite-pools", JSON.stringify(currentfavorites))
            setFavorites(currentfavorites)
        })
        window.addEventListener("remove-favorite", (e) => {
            let currentfavorites = JSON.parse(window.localStorage.getItem("favorite-pools")) || []
            currentfavorites = currentfavorites.filter((address) => address !== e.detail.address && address !== ethers.utils.getAddress(e.detail.address) && address !== e.detail.address.toLowerCase())
            window.localStorage.setItem("favorite-pools", JSON.stringify(currentfavorites))
            setFavorites(currentfavorites)
        })
    }, [])
    
    return { favorites, addFavorite, removeFavorite }
}