import { Disclosure } from '@headlessui/react'
import { ConnectKitButton } from "connectkit";
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Docs', href: 'https://docs.surge-fi.com', current: false },
  { name: 'FAQs', href: '/faqs', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  return (
    <Disclosure as="nav" className="border-b-2 border-black-200">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <a href="/">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="/logo.svg"
                      alt="Surge"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="/logo.svg"
                      alt="Surge"
                    />
                  </a>
                </div>
                <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-white',
                        'px-3 py-2 text-sm font-semibold'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div className="ml-6 flex items-center">
                  <ConnectKitButton
                  customTheme={{
                    "--ck-connectbutton-border-radius": "0.375rem",
                    "--ck-connectbutton-background": "#202124",
                    "--ck-connectbutton-hover-background": "#2A2B2E",
                    "--ck-connectbutton-active-background": "#323336",
                    "--ck-connectbutton-color": "#ffffff",
                    "--ck-connectbutton-hover-color": "#ffffff",
                    "--ck-connectbutton-active-color": "#ffffff",
                    "--ck-connectbutton-box-shadow": "0 0 0 2px rgba(0, 0, 0, 0.2)",
                    "--ck-connectbutton-hover-box-shadow": "0 0 0 2px rgba(0, 0, 0, 0.3)",
                  }}
                  />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-black-200 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
