import React, { useState, useEffect } from "react";
import useLocalStorage from "use-local-storage";
import { useParams } from "react-router-dom";
import usePool from "../hooks/usePool";
import { useAccount, useNetwork } from "wagmi";
import UserPosition from "../components/UserPosition";
import Lend from "../components/Lend";
import Withdraw from "../components/Withdraw";
import Borrow from "../components/Borrow";
import Repay from "../components/Repay";
import Liquidate from "../components/Liquidate";
import AddCollateral from "../components/AddCollateral";
import RemoveCollateral from "../components/RemoveCollateral";
import PoolPositions from "../components/PoolPositions";
import Alert from "../components/Alert";
import { ArrowLeftIcon, ArrowDownIcon, ArrowUpIcon, ArrowUpRightIcon } from '@heroicons/react/20/solid'
import CONSTANTS from "../constants";
import FavoriteButton from "../components/FavoriteButton";
import ToolTip from "../components/ToolTip";
import TokenIcon from "../components/TokenIcon";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function Pool() {
    const [lendOpen, setLendOpen] = useState(false)
    const [liquidateOpen, setLiquidateOpen] = useState(false)
    const [withdrawOpen, setWithdrawOpen] = useState(false)
    const [addCollateralOpen, setAddCollateralOpen] = useState(false)
    const [removeCollateralOpen, setRemoveCollateralOpen] = useState(false)
    const [borrowOpen, setBorrowOpen] = useState(false)
    const [repayOpen, setRepayOpen] = useState(false)
    const { poolName } = useParams();
    const poolPrefix = poolName.substring(0, 1);
    const poolNumber = poolName.substring(1);

    const [alertDismissed, setAlertDismissed] = useLocalStorage("alert-dismissed-"+poolName, false);

    const { chain } = useNetwork()
    const network = chain?.network;
    const expectedPrefix = CONSTANTS.networks[network]?.prefix;

    let wrongNetwork = false;
    let suggestedNetwork = false; // false means unsupported network
    if(poolPrefix && expectedPrefix !== poolPrefix) {
        wrongNetwork = true
        for (const [key, value] of Object.entries(CONSTANTS.networks)) {
            if(value.prefix === poolPrefix) {
                suggestedNetwork = key;
            }
        }
    };

    const { address: account } = useAccount();
    const poolData = usePool(poolNumber, account);
    const {
        collateralSymbol,
        loanSymbol,
        liquidity,
        suppliedLoanTokens,
        collateralRatio,
        collateralRatioRaw,
        maxCollateralRatio,
        utilization,
        borrowRate,
        supplyRate,
        kink,
        kinkRaw,
        investment,
        investmentRaw,
        debt,
        collateralBalance,
        collateralBalanceRaw,
        totalDebt,
        collateralRatioFallSpeed,
        collateralRatioRecoverySpeed,
        loanDecimals,
        collateralDecimals,
        collateralAddress,
        loanAddress,
        address,
        refetch,
        userCollateralBalanceRaw,
        userLoanBalanceRaw,
        userLoanBalance,
        userCollateralBalance,
        borrowMaxRaw,
        globalBorrowMaxRaw,
        debtRaw,
        maxRemoveCollateralRaw,
        liquidityRaw,
        collateralRatioFallDuration,
        collateralRatioRecoveryDuration,
        minRate,
        minRateRaw,
        surgeRate,
        surgeRateRaw,
        maxRate,
        maxRateRaw,
        feeRaw,
        isLoading,
        suppliedLoanTokensRaw,
        totalDebtRaw,
        lastDepositTimestamp
     } = poolData;

    let changeDirection;
    if(Number(utilization) > Number(kink)) {
        changeDirection = 'down'
    } else if(Number(collateralRatio) < Number(maxCollateralRatio)) {
        changeDirection = 'up'
    } else {
        changeDirection = 'none'
    }

    const explorerAddrPrefix = chain?.blockExplorers.default.url;
    const collateralTokenUrl = `${explorerAddrPrefix}/token/${collateralAddress}`;
    const loanTokenUrl = `${explorerAddrPrefix}/token/${loanAddress}`;

     const stats = [
        { name: 'Liquidity', stat: `${liquidity} ${loanSymbol}`, substat: `Out of ${suppliedLoanTokens} supplied`, info: "Liquidity is the amount of loan tokens available to be borrowed." },
        { name: 'Total borrows', stat: `${totalDebt} ${loanSymbol}`, info: "Total borrows is the total amount of loan tokens borrowed from the pool." },
        { name: 'Collateral ratio', stat: `${collateralRatio} : 1`, substat: `Max at ${maxCollateralRatio} : 1`, change: `${changeDirection === "up"? collateralRatioRecoverySpeed :collateralRatioFallSpeed}`, changeDirection, info: "Collateral ratio is the amount of collateral tokens required to borrow a loan token. The higher the collateral ratio, the more collateral tokens are required to borrow a loan token." },
        { name: 'Utilization', stat: `${utilization}%`, substat: `Surge at ${kink}%`, info: "Utilization is the percentage of the pool's liquidity that has been borrowed." },
    ]

    const [loanImage, setLoanImage] = useState({})
    const [collateralImage, setCollateralImage] = useState({})

    useEffect(() => {
        const fetchTokenList = async () => {
          const response = await fetch('https://tokens.coingecko.com/arbitrum-one/all.json')
          const data = await response.json()
  
          const tokensObj = data?.tokens.reduce((acc, token) => {
            acc[token.address] = token.logoURI
            return acc
          }, {})
  
          // for testing
          tokensObj["0xaff4481d10270f50f203e0763e2597776068cbc5"] = "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
          tokensObj["0x022e292b44b5a146f2e8ee36ff44d3dd863c915c"] = "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389"
  
          setLoanImage(tokensObj[loanAddress.toLowerCase()])
          setCollateralImage(tokensObj[collateralAddress.toLowerCase()])
        }
        fetchTokenList()
      }, [loanAddress, collateralAddress])

    return (
    <div>
        {!wrongNetwork && !isLoading && Object.keys(poolData).length > 0 && poolData.liquidity &&
        <div className="grid grid-col-1 gap-y-4 sm:gap-y-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div>
                <nav className="mb-4 sm:mb-1 lg:flex lg:items-center lg:justify-between" aria-label="Breadcrumb">
                    <div className="flex items-center">
                        <a
                            href="/"
                            className="text-xs inline-flex font-medium text-gray-300 rounded-full border-2 border-black-400 hover:bg-black-400 hover:bg-black-400 px-2 py-0.5">
                            <ArrowLeftIcon className="h-4 w-4 flex-shrink-0 text-primary-500" aria-hidden="true" />
                            <span className="ml-0.5">
                                Go back
                                </span>
                        </a>
                    </div>
                </nav>
                
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1 mt-2">
                        <h1 className="inline text-2xl font-extrabold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight align-middle">
                        { poolName }
                        </h1>
                        <span className="inline-flex align-middle"><FavoriteButton address={address} /></span>
                        <a href={loanTokenUrl} target="_blank" className="inline-flex items-center rounded-md bg-black-300 px-2.5 py-0.5 text-medium font-medium text-white ml-3 align-middle hover:bg-black-400">
                            <span className="text-gray-400 mr-1">Loan</span> 
                            <div className="mt-1"><TokenIcon url={loanImage}/></div> {loanSymbol}
                            <ArrowUpRightIcon className="h-5 w-5 flex-shrink-0 text-primary-500 ml-1" aria-hidden="true" />
                        </a> 
                        <a href={collateralTokenUrl} target="_blank" className="inline-flex items-center rounded-md bg-black-300 px-2.5 py-0.5 text-medium font-medium text-white ml-3 align-middle hover:bg-black-400">
                            <span className="text-gray-400 mr-1">Collateral</span>
                             <div className="mt-1"><TokenIcon url={collateralImage}/></div>{collateralSymbol}
                            <ArrowUpRightIcon className="h-5 w-5 flex-shrink-0 text-primary-500 ml-1" aria-hidden="true" />
                        </a> 
                    </div>
                    <div className="inline-flex items-center rounded-lg border border-2 border-black-400 px-6 py-2 mt-4 lg:mt-0 bg-transparent">
                        <span>
                            <span className="text-gray-300 pr-1">Supply APR</span>
                            <span className="font-semibold text-green-600"> {supplyRate}% </span>
                        </span>
                        <span className="ml-3 pl-3 border-l-2 border-black-400">
                            <span className="text-gray-300 pr-1">Borrow APR</span>
                            <span className="font-semibold text-yellow-600"> {borrowRate}% </span>
                        </span>
                    </div>
                </div>
                {!alertDismissed &&<Alert onDismiss={() => setAlertDismissed(true)} title="Do your research" content="Anyone can create a pool with bad risk parameters and fake token names. Please verify that the loan token and collateral token addresses above are correct before using this pool. Also read the pool info and take some time to understand the potential risks." />}
            </div>
            <div className="grid grid-cols-6 gap-8">
                <div className="lg:col-span-4 sm:col-span-6 col-span-6 columns-lg">
                    <Lend open={lendOpen} setOpen={setLendOpen} address={address} totalDebtRaw={totalDebtRaw} suppliedRaw={suppliedLoanTokensRaw} surgeMantissaRaw={kinkRaw} minRateRaw={minRateRaw} surgeRateRaw={surgeRateRaw} maxRateRaw={maxRateRaw} feeRaw={feeRaw} loanDecimals={loanDecimals} loanAddress={loanAddress} onReceipt={refetch} loanSymbol={loanSymbol} userLoanBalanceRaw={userLoanBalanceRaw}/>
                    <Repay open={repayOpen} setOpen={setRepayOpen} address={address} loanDecimals={loanDecimals} loanAddress={loanAddress} onReceipt={refetch} loanSymbol={loanSymbol} debt={debt} debtRaw={debtRaw} collateralRatio={collateralRatio} collateralBalance={collateralBalance} userLoanBalanceRaw={userLoanBalanceRaw} />
                    <Liquidate open={liquidateOpen} setOpen={setLiquidateOpen} address={address} loanDecimals={loanDecimals} loanAddress={loanAddress} onReceipt={refetch} loanSymbol={loanSymbol} userLoanBalanceRaw={userLoanBalanceRaw} userLoanBalance={userLoanBalance}/>
                    <Withdraw open={withdrawOpen} setOpen={setWithdrawOpen} address={address} loanDecimals={loanDecimals} onReceipt={refetch} loanSymbol={loanSymbol} userDepositRaw={investmentRaw} userDeposit={investment} liquidityRaw={liquidityRaw} lastDepositTimestamp={lastDepositTimestamp}/>
                    <Borrow open={borrowOpen} setOpen={setBorrowOpen} address={address} totalDebtRaw={totalDebtRaw} suppliedRaw={suppliedLoanTokensRaw} surgeMantissaRaw={kinkRaw} minRateRaw={minRateRaw} surgeRateRaw={surgeRateRaw} maxRateRaw={maxRateRaw} loanDecimals={loanDecimals} collateralDecimals={collateralDecimals} onReceipt={refetch} loanSymbol={loanSymbol} borrowMaxRaw={borrowMaxRaw} globalBorrowMaxRaw={globalBorrowMaxRaw} debtRaw={debtRaw} collateralRatioRaw={collateralRatioRaw} collateralBalanceRaw={collateralBalanceRaw}/>
                    <AddCollateral open={addCollateralOpen} setOpen={setAddCollateralOpen} collateralBalanceRaw={collateralBalanceRaw} address={address} collateralDecimals={collateralDecimals} collateralAddress={collateralAddress} onReceipt={refetch} collateralSymbol={collateralSymbol} userCollateralBalance={userCollateralBalance} userCollateralBalanceRaw={userCollateralBalanceRaw}/>
                    <RemoveCollateral open={removeCollateralOpen} setOpen={setRemoveCollateralOpen} address={address} collateralDecimals={collateralDecimals} collateralSymbol={collateralSymbol} onReceipt={refetch} maxRemoveCollateralRaw={maxRemoveCollateralRaw} collateralBalanceRaw={collateralBalanceRaw}/>
                    <div className="rounded-lg bg-black-200 px-4 py-5 shadow sm:p-6">
                        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
                            {stats.map((item) => (
                            <div key={item.name} className="rounded-lg px-4 py-4 border border-black-500 sm:p-3">
                                <dt className="text-sm text-gray-400 inline-flex items-center">
                                    {item.name}
                                    <ToolTip text={item.info} />
                                </dt>
                                <h2 className="mt-1 font-bold text-lg tracking-tight text-white">
                                    {item.stat}
                                    {item.change && item.changeDirection && item.changeDirection !== 'none' &&
                                    <div className={classNames(
                                    item.changeDirection === 'up' ? 'bg-green-600 text-white' : 'bg-red-500 text-white',
                                    'text-white inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium md:mt-2 lg:mt-0 ml-2'
                                    )}
                                    >
                                        {item.changeDirection === 'up' ? (
                                        <ArrowUpIcon
                                            className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-white"
                                            aria-hidden="true"
                                        />
                                        ) : (
                                        <ArrowDownIcon
                                            className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-white"
                                            aria-hidden="true"
                                        />
                                        )}

                                        <span className="sr-only"> {item.changeDirection === 'up' ? 'Increased' : 'Decreased'} by </span>
                                        {item.change}/h
                                    </div>}
                                    <span className="flex items-baseline justify-between font-medium">
                                    {item.substat && <span className="text-sm text-gray-400">{item.substat}</span>}
                                    </span>
                                </h2>
                            </div>
                            ))}
                        </dl>
                        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                            <div>
                                <div className="inline-flex items-center">
                                    <span className="leading-6 font-medium text-white">
                                    Collateral ratio durations
                                    </span>
                                    <ToolTip text="This is a test" />
                                </div>  
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-2">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-400">Max ratio → zero</dt>
                                        <dd className="mt-1 text-sm text-white">{ collateralRatioFallDuration }</dd>
                                    </div>
                                    <div className="sm:col-span-1 sm:text-right text-left">
                                        <dt className="text-sm font-medium text-gray-400">Zero → max ratio</dt>
                                        <dd className="mt-1 text-sm text-white">{ collateralRatioRecoveryDuration }</dd>
                                    </div>
                                </dl>
                            </div>
                            <div>
                                <div className="inline-flex items-center">
                                    <span className="leading-6 font-medium text-white">
                                    Borrow rate model
                                    </span>
                                    <ToolTip text="This is a test" />
                                </div>                                 
                                        
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 mt-2">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-400">Min rate</dt>
                                        <dd className="mt-1 text-sm text-white">{ minRate }%</dd>
                                    </div>
                                    <div className="sm:col-span-1 sm:text-center text-left">
                                        <dt className="text-sm font-medium text-gray-400">Surge rate</dt>
                                        <dd className="mt-1 text-sm text-white">{ surgeRate }%</dd>
                                    </div>
                                    <div className="sm:col-span-1 sm:text-right text-left">
                                        <dt className="text-sm font-medium text-gray-400">Max rate</dt>
                                        <dd className="mt-1 text-sm text-white">{ maxRate }%</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                    {address && <PoolPositions address={address} collateralRatioRaw={collateralRatioRaw} loanDecimals={loanDecimals} collateralDecimals={collateralDecimals} loanSymbol={loanSymbol} collateralSymbol={collateralSymbol} onLiquidate={setLiquidateOpen} />}
                </div>
                <div className="order-first lg:order-none lg:col-span-2 sm:col-span-6 col-span-6">
                    <UserPosition
                        debtRaw={debtRaw}
                        investmentRaw={investmentRaw}
                        collateralBalanceRaw={collateralBalanceRaw}
                        collateralSymbol={collateralSymbol}
                        loanSymbol={loanSymbol}
                        suppliedLoanTokensRaw={suppliedLoanTokensRaw}
                        collateralRatioRaw={collateralRatioRaw}
                        setLendOpen={setLendOpen}
                        setAddCollateralOpen={setAddCollateralOpen}
                        setBorrowOpen={setBorrowOpen}
                        setWithdrawOpen={setWithdrawOpen}
                        setRepayOpen={setRepayOpen}
                        setRemoveCollateralOpen={setRemoveCollateralOpen}
                        supplyRate={supplyRate}
                        borrowRate={borrowRate}
                        loanDecimals={loanDecimals}
                        collateralDecimals={collateralDecimals}
                        />
                </div>
            </div>
        </div>}
        {wrongNetwork && suggestedNetwork &&
            <div className="text-white">
                Wrong network. Please switch to {suggestedNetwork} to view this pool.
            </div>
        }
        {wrongNetwork && !suggestedNetwork &&
            <div className="text-white">
                This pool does not exist.
            </div>
        }
        {!wrongNetwork && isLoading && Object.keys(poolData).length === 0 &&
            <div className="text-white">
                Loading
            </div>
        }
        {!wrongNetwork && !isLoading && Object.keys(poolData).length === 0 &&
            <div className="text-white">
                Error fetching pool data
            </div>
        }
    </div>
    )
}