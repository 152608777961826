import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useAccount, useContractRead, useSigner } from 'wagmi'
import { ethers } from 'ethers'
import ActionButton from './ActionButton'
import ActionInput from './ActionInput'
import useFavorites from '../hooks/useFavorites'
import numeral from 'numeral'
import formatBN from '../utils/formatBN'


export default function Lend({open, setOpen, address, totalDebtRaw, suppliedRaw, surgeMantissaRaw, minRateRaw, surgeRateRaw, maxRateRaw, feeRaw, loanDecimals, loanAddress, onReceipt, loanSymbol, userLoanBalanceRaw}) {

  const { addFavorite } = useFavorites()

  const [amount, setAmount] = useState('')

  let amountErrorMessage = '';
  if(amount) {
    if(amount.lte(0)) amountErrorMessage = 'Amount must be greater than 0'
    else if(amount.gt(userLoanBalanceRaw)) amountErrorMessage = 'Insufficient balance'
  }

  const [buttonText, setButtonText] = useState('Supply')
  const [txState, setTxState] = useState("ready")
  let actionState = "disabled";
  if(txState === "ready" && amount && !amountErrorMessage) {
    actionState = "ready"
  } else if(txState === "loading") {
    actionState = "loading"
  } else if(txState === "success") {
    actionState = "success"
  }
  const { address :account } = useAccount()
    function setAmountBigNumber(value) {
      try {
        setAmount(ethers.utils.parseUnits(value, loanDecimals))
      } catch(e) {
        setAmount('')
      }
    }

    const BN = ethers.BigNumber.from

    const poolAbi = [
        "function deposit(uint) external"
    ];

    const loanAbi = [
        "function allowance(address, address) external view returns (uint)",
        "function approve(address, uint) external returns (bool)"
    ];

    const { data: allowanceData, refetch: allowanceRefetch } = useContractRead({
      address: loanAddress,
      abi: loanAbi,
      functionName: 'allowance',
      args: [account, address]
  })


  function getExpectedBorrowRate(utilizationMantissa, surgeMantissa, minRateMantissa, surgeRateMantissa, maxRateMantissa){
    const util = BN(utilizationMantissa)
    const surgeThreshold = BN(surgeMantissa)
    const minRate = BN(minRateMantissa)
    const surgeRate = BN(surgeRateMantissa)
    const maxRate = BN(maxRateMantissa)

    if(util.lte(surgeThreshold)){
      return surgeRate.sub(minRate).mul(util).div(surgeThreshold).add(minRate)
    } else {
      return maxRate.sub(surgeRate).mul(util.sub(surgeThreshold)).div(BN(10).pow(18).sub(surgeThreshold)).add(surgeRate)
    }
  }
  
  function getUtilizationMantissa(totalDebt, supplied){
    if (supplied.eq(0)) return BN(0)
    return BN(totalDebt).mul(BN(10).pow(18)).div(BN(supplied))
  }

  function getExpectedSupplyRate(feeRaw, expectedBorrowAPR, utilizationMantissa){
    const oneMinus = BN(10).pow(18).sub(feeRaw)
    const rateToPool = expectedBorrowAPR.mul(oneMinus).div(BN(10).pow(18))
    return utilizationMantissa.mul(rateToPool).div(BN(10).pow(18))
  }

  const exectedSuppliedRaw = amount? suppliedRaw.add(amount) : suppliedRaw

  const utilizationMantissa = getUtilizationMantissa(totalDebtRaw, exectedSuppliedRaw)

  const expectedBorrowAPR = getExpectedBorrowRate(utilizationMantissa, surgeMantissaRaw, minRateRaw, surgeRateRaw, maxRateRaw)

  const expectedSupplyAPR = formatBN(getExpectedSupplyRate(feeRaw, expectedBorrowAPR, utilizationMantissa).mul(100))


  const { data: signer } = useSigner()


    async function submit(e) {
      e.preventDefault()
      setTxState("loading")
      try {
        const poolContract = new ethers.Contract(address, poolAbi, signer)
        const loanContract = new ethers.Contract(loanAddress, loanAbi, signer)
          if(allowanceData.lt(amount)) {
              setButtonText('Approving...')
              const approveTx = await loanContract.approve(address, ethers.constants.MaxUint256);
              await approveTx.wait()
              await allowanceRefetch()
          }
          setButtonText('Supplying...')
          const tx = await poolContract.deposit(amount);
          await tx.wait()
          onReceipt()
          addFavorite(address)
          setTxState("success")
          setButtonText('Supplied')
          setAmount('')
          setTimeout(() => {
            setTimeout(() => {
              setOpen(false)
              setTxState("ready")
              setButtonText('Supply')
            }, 1000)
          }, 2000)
        } catch(e) {
          setTxState("ready")
          setButtonText('Supply')
      }
    }

    function close() {
      setAmount('')
      setOpen(false)
    }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
      <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-100 bg-opacity-75 transition-opacity" />
        </Transition.Child>


        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-black-500 bg-black-200 shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-black-300 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">Supply tokens</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={close}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-400">
                            Supply tokens to the pool to earn interest
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                          <ActionInput
                            max={ethers.utils.formatUnits(userLoanBalanceRaw, loanDecimals)}
                            label="Amount"
                            placeholder={"available: " + numeral(ethers.utils.formatUnits(userLoanBalanceRaw, loanDecimals)).format('0,0.000')}
                            unit={loanSymbol}
                            onChange={setAmountBigNumber}
                            errorMessage={amountErrorMessage}
                            helperText="The amount of tokens you want to supply."
                          />
                          </div>
                          <div>
                              <div className="grid-cols-2 flex">
                                  <p className="font-medium text-gray-400 leading-6 text-white flex-auto">Expected supply APR</p>
                              </div>
                              <div className="mt-1 text-white">
                                <p>
                                  {expectedSupplyAPR}%
                                </p>  
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-black-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        onClick={close}
                      >
                        Close
                      </button>
                      <ActionButton
                        onClick={submit}
                        text={buttonText}
                        state={actionState}
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
