const faqs = [
    {
      question: "What is Surge protocol?",
      answer:
        "Surge Protocol is a decentralized lending and borrowing platform that uses a dynamic collateral ratio as a function of utilization rate over time rather than relying on price feeds. This allows for a more robust and reliable lending and borrowing experience for users of all tokens.",
    },
    {
      question: "How does Surge Protocol differ from other DeFi lending protocols?",
      answer:
        "Other DeFi lending protocols typically only support a small number of highly liquid tokens due to the lack of reliable and manipulation-resistant price feeds. Surge Protocol eliminates this limitation by using a dynamic collateral ratio based on utilization rate rather than price feeds.",
    },
    {
      question: "Who can deploy a lending pool on Surge Protocol?",
      answer:
        "Anyone can deploy a lending pool on Surge Protocol by following the simple process outlined in the docs. Pool deployers determine all pool parameters, but these parameters cannot be changed once the pool is deployed.",
    },
    {
      question: "What are collateral ratio and utilization ratio?",
      answer:
        "Collateral ratio is a risk parameter decided by lenders to set the maximum loan limits. It represent the pool’s risk score where high collateral ratio protects the lender from bad debt, low collateral ratio creates a sustainable credit platform. It is basically a risk assessment structure.",
    },    
    {
      question: "Will Surge be deployed on [my favorite chain]?",
      answer:
        "We plan to first deploy Surge Protocol on Ethereum mainnet, followed by L2s, and other EVM chains (in that order).",
    },
  ]
  
  export default function FAQs() {
    return (
      <div>
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-16 lg:px-8">
          <h2 className="text-3xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
          <p className="mt-6 max-w-2xl text-base leading-7 text-gray-300">
            Can’t find the answer you’re looking for? Reach out to our <a href="https://www.twitter.com/surge_fi" target="_blank" className="text-primary-600 font-bold">Twitter account</a> for more information.
          </p>
          <div className="mt-20">
            <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
              {faqs.map((faq) => (
                <div key={faq.id}>
                  <dt className="text-lg font-bold leading-7 text-white">{faq.question}</dt>
                  <dd className="mt-2 text-base leading-7 text-gray-400">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }