import { useEffect, useState } from 'react'
import PoolItem from "./PoolItem"
import usePools from '../hooks/usePools'
import CONSTANTS from '../constants'
import { useNetwork } from 'wagmi'
import useFavorites from '../hooks/useFavorites'
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';

export default function PoolsList() {

    const { chain } = useNetwork()
    const network = chain?.network;
    const prefix = CONSTANTS.networks[network]?.prefix;

    const [hideEmpty, setHideEmpty] = useState(true)

    const [search, setSearch] = useState('')
    const [debouncedSearch] = useDebounce(search, 500)
    const [tokenList, setTokenList] = useState([])

    useEffect(() => {
      const fetchTokenList = async () => {
        const response = await fetch('https://tokens.coingecko.com/arbitrum-one/all.json')
        const data = await response.json()

        const tokensObj = data?.tokens.reduce((acc, token) => {
          acc[token.address] = token.logoURI
          return acc
        }, {})

        // for testing
        tokensObj["0xaff4481d10270f50f203e0763e2597776068cbc5"] = "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
        tokensObj["0x022e292b44b5a146f2e8ee36ff44d3dd863c915c"] = "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389"

        setTokenList(tokensObj)
      }
      fetchTokenList()
    }, [])

    const { poolsList, loading, showMore } = usePools(hideEmpty, debouncedSearch.trim())
    
    poolsList.map((pool) => {
      pool.loanImage = tokenList[pool.loanAddress.toLowerCase()]
      pool.collateralImage = tokenList[pool.collateralAddress.toLowerCase()]
    })

    const { ref } = useInView({
      threshold: 0,
      onChange: (inView) => {
        if (inView && !loading) {
          showMore()
        }
      }
    });

    const { favorites } = useFavorites();

    const { poolsList: favoritePoolsList } = usePools(false, '', favorites)

    favoritePoolsList.map((pool) => {
      pool.loanImage = tokenList[pool.loanAddress.toLowerCase()]
      pool.collateralImage = tokenList[pool.collateralAddress.toLowerCase()]
    })

    return (
      <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      {favorites.length > 0 && favoritePoolsList.length > 0 &&
      <>
      <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-white">Saved pools</h1>
          </div>
        </div>
        <div className="sm:-mx-6 -mx-4 mb-8 mt-4 md:mx-0 overflow-hidden shadow mx-0 rounded-lg bg-black-200">
          <table className="min-w-full divide-y divide-black-500">
          <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 hidden sm:table-cell">
                  Name
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 sm:hidden">
                  Pool
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 hidden sm:table-cell">
                  Loan token
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white hidden sm:table-cell"
                >
                  Collateral token
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Liquidity <span className="sm:inline hidden">/ Supplied</span>
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  <span className="sm:inline hidden">Collateral Ratio / Max</span>
                  <span className="sm:hidden">CR</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell hidden">
                  Utilization
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 sm:hidden">
                  Rates
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell hidden">
                  Supply Rate
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell hidden">
                  Borrow Rate
                </th>
              </tr>
            </thead>
            <tbody>
            {favoritePoolsList.map((pool, i) => (
              <PoolItem key={pool.address} pool={pool}/>
            ))}
            </tbody>
          </table>
        </div>
        </>}
        <div className="flex items-center">
          <div className="flex-auto">
            <h1 className="text-2xl font-extrabold leading-7 text-white">{chain?.name} pools</h1>
          </div>
          <div>
            <a
              href="/create"
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
            >
              + Create a pool
            </a>
          </div>
        </div>
        <div className="mt-4 grid grid-cols-6">
          <input
            type="text"
            onChange={e => setSearch(e.target.value)}
            value={search}
            name="search"
            className="sm:col-span-5 col-span-6 w-full border-0 rounded-lg bg-black-300 focus:ring-2 focus:ring-primary-500 font-medium text-white"
            placeholder={`e.g. USDT, BTC, ${prefix}123, 0x1234...`}
          />
          <div className="flex sm:justify-end mt-3 sm:mt-0 items-center col-span-6 sm:col-span-1">
            <input
              name="show-empty"
              type="checkbox"
              checked={hideEmpty}
              onChange={e => setHideEmpty(e.target.checked)}
              className="h-4 w-4 rounded border-black-900 text-primary-600 bg-transparent focus:ring-primary-500"
            />
            <a href="" onClick={(e) => {e.preventDefault(); setHideEmpty(!hideEmpty)}} htmlFor="show-empty" className="ml-2 font-sm text-white">Hide empty pools</a>
          </div>
        </div>
        <div className="sm:-mx-6 -mx-4 mb-8 mt-4 md:mx-0 overflow-hidden shadow mx-0 rounded-lg bg-black-200">
          <table className="min-w-full divide-y divide-black-500">
          <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 hidden sm:table-cell">
                  Name
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 sm:hidden">
                  Pool
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 hidden sm:table-cell">
                  Loan token
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white hidden sm:table-cell"
                >
                  Collateral token
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  Liquidity <span className="sm:inline hidden">/ Supplied</span>
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                >
                  <span className="sm:inline hidden">Collateral ratio / Max</span>
                  <span className="sm:hidden">CR</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell hidden">
                  Utilization
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 sm:hidden">
                  Rates
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell hidden">
                  Supply Rate
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white sm:table-cell hidden">
                  Borrow Rate
                </th>
              </tr>
            </thead>
            <tbody>
              {poolsList?.map((pool, i) => (
                <PoolItem key={pool.address} pool={pool} hideEmpty={hideEmpty} />
              ))}
            </tbody>
          </table>

          <span ref={ref}/>
        </div>
      </div>
      </>
    )
  }
  