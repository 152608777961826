import { useCoingeckoTokenPrice } from "@usedapp/coingecko";

// for Goerli testnet purposes
const HARDCODED_PRICES = {
    "0xaFF4481D10270F50f203E0763e2597776068CBc5":1000, // WEENUS
    "0x022E292b44B5a146F2e8ee36Ff44D3dd863C915c":50, // XEENUS
    "0xc6fDe3FD2Cc2b173aEC24cc3f267cb3Cd78a26B7":1 // ZEENUS
}

export default function usePrice(address) {

    const price = useCoingeckoTokenPrice(address, 'usd');

    if(!price && HARDCODED_PRICES[address]) return HARDCODED_PRICES[address]

    return price
}