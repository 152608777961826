import { useNetwork } from 'wagmi'
import CONSTANTS from '../constants'
import { useEffect, useState } from 'react';
import axios from 'axios'

export default function usePools(hideEmpty = false, search = '', poolAddresses = [], sorting = 'liquidity') {
    const { chain } = useNetwork()

    const network = chain?.network;

    const poolsPerPage = 25;
    const [pageNumber, setPageNumber] = useState(1)

    const showMore = () => {
        setPageNumber(() => pageNumber + 1)

        const getPools = async () => {
            try {
                setLoading(true)
                setError(false)
                const res = await axios.get(`/api/pools?network=${network}&pageNumber=${pageNumber}&poolsPerPage=${poolsPerPage}&hideEmpty=${hideEmpty}&search=${search}&sorting=${sorting}`)
                const pools = res.data.poolsList
                setPoolsList(() => poolsList.concat(pools))
                setLoading(false)
            } catch (e) {
                console.log(e)
                setError(true)
                setLoading(false)
            }
        }
        getPools()
    }
    
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [prevPoolAddresses, setPrevPoolAddresses] = useState([])

    const [poolsList, setPoolsList] = useState([])


    // default view
    useEffect(() => {
        if(!network) return
        if(poolAddresses.length > 0) return

        const getPools = async () => {
            try {
                setLoading(true)
                setError(false)
                setPageNumber(1)
                setPoolsList([])
                const res = await axios.get(`/api/pools?network=${network}&pageNumber=${pageNumber}&poolsPerPage=${poolsPerPage}&hideEmpty=${hideEmpty}&search=${search}&sorting=${sorting}&poolAddresses=${poolAddresses.join(',')}`)
                const pools = res.data.poolsList
                setPoolsList(() => pools)
                setLoading(false)
            } catch (e) {
                console.log(e)
                setError(true)
                setLoading(false)
            }
        }
        getPools()
    }, [network, hideEmpty, search, sorting])

    // favorite pools
    useEffect(() => {
        if(!network) return
        if(poolAddresses.length === 0) return
        if(prevPoolAddresses.length === poolAddresses.length) return

        const getPools = async () => {
            try {
                setLoading(true)
                setError(false)
                setPageNumber(1)
                const res = await axios.get(`/api/pools?network=${network}&pageNumber=${pageNumber}&poolsPerPage=${poolsPerPage}&hideEmpty=${hideEmpty}&search=${search}&sorting=${sorting}&poolAddresses=${poolAddresses.join(',')}`)
                const pools = res.data.poolsList
                setPrevPoolAddresses(() => poolAddresses)
                setPoolsList(() => pools)
                setLoading(false)
            } catch (e) {
                console.log(e)
                setError(true)
                setLoading(false)
            }
        }
        getPools()
    }, [network, hideEmpty, search, sorting, poolAddresses])

    return {
        poolsList,
        loading,
        error,
        showMore
    }
}