import React, { useRef } from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ActionInput ({ label, value, onChange, placeholder, unit, max, type = "number", helperText, errorMessage, disabled, unitOptions = [], onOptionChange, defaultOption}) {
    const ref = useRef(null);

    function onMax(e) {
        e.preventDefault();
        onChange(max);
        // change ref input value to max
        ref.current.value = max;
    }

    let className = "relative rounded-md border px-3 py-2 shadow-sm focus-within:ring-1"
    if (errorMessage) {
        className += " border-red-600 focus-within:border-red-600 focus-within:ring-red-600 border-red-300"
    } else {
        className += " border-black-400 focus-within:border-primary-600 focus-within:ring-primary-600"
    }

    return (
    <div>
        <div className={className}>
            <label className="block text-sm font-regular text-white">
            {label}
            </label>
            <input
            ref={ref}
            min="0"
            max={max}
            disabled={disabled}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            type={type}
            className="block w-full border-0 p-0 text-white placeholder-gray-500 focus:ring-0 sm:text-medium m-0 bg-transparent"
            placeholder={placeholder}
            />
            {unitOptions.length > 0 && <div className="absolute inset-y-0 right-0 flex items-center">
                <label className="sr-only">
                    Currency
                </label>
                <select
                    defaultValue={defaultOption}
                    onChange={(e) => onOptionChange(e.target.value)}
                    className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 fill-primary-500 text-primary-500 font-medium sm:text-sm text-right"
                >
                    {unitOptions.map((option) => (
                    <option key={option}>{option}</option>
                    ))}
                </select>
            </div>}
            {unit && <div className={classNames(max? "pr-16": "pr-4", "pointer-events-none absolute inset-y-0 right-0 flex items-center pt-0")}>
            <span className="text-gray-400 font-medium sm:text-sm">
                {unit}
            </span>
            </div>}

            {max && <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <button onClick={onMax} className="inline-flex items-center rounded bg-black-400 px-2 font-sans text-sm font-semibold text-primary-500">
                    MAX
                </button>
            </div>}
        </div>
       {helperText && !errorMessage && <p className="mt-2 text-sm text-gray-400" id="email-description">
        {helperText}
        </p>}
        {errorMessage && <p className="mt-2 text-sm text-red-600" id="email-error">
        {errorMessage}
      </p>}
    </div>
    )
}